import styled from 'styled-components';

interface IImgEvent {
  src: string;
  alt?: string;
}

export const Container = styled.div`
  background: #f4f0ec;
  padding-bottom: 30%;
  min-height: 100vh;

  .infinite-scroll {
    max-height: 100vh;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
  }

  h1 {
    color: #ff6900;
    font-family: 'Poppins';
    font-size: 26px;
    font-weight: 500;
    line-height: 108%;
  }

  .card-events a {
    background-color: #fff;
    border-radius: 8px;
  }

  .card-events img {
    border-radius: 8px 8px 0 0;
  }

  .card-events a span:nth-child(2) {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }

  .card-events span {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .bg-white {
    border-radius: 8px;
  }

  .input-search {
    flex: 1;
  }
`;

export const ImgEvent = styled.div<IImgEvent>`
  min-height: 175px;

  border-radius: 8px 8px 0 0;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
