/* eslint-disable import/no-duplicates */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  FaEnvelope,
  FaFacebookF,
  FaLink,
  FaTelegramPlane,
  FaWhatsapp,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { Banner, Circle, ColorBox, Container, Img, ShareBox } from './styles';
import Carousel from '~/components/Carousel';
import ButtonBack from '~/components/ButtonBack';
import {
  KrBoxes,
  KrCalendar,
  KrHanger,
  KrMapMark,
} from '~/components/KoroIcons';

import share from '~/assets/icons/share.svg';
import api from '~/services/api';
import Toast from '~/utils/toast';

interface IParams {
  slug: string;
  preview: string;
}

interface IArchive {
  id: number;
  name: string;
  archive_url: string;
}

interface IArchiveProduct {
  archive: IArchive;
}

interface ISizeResponse {
  id: number;
  size: string;
}

interface IColorResponse {
  id: number;
  color: string;
}

interface IQuantityResponse {
  id: number;
  color_id?: number;
  quantity: number;
  color?: IColorResponse;
  size?: ISizeResponse;
  archivesProducts: IArchiveProduct[];
}

interface IProductResponse {
  id: number;
  name: string;
  quantities: IQuantityResponse[];
}

interface IKitResponse {
  id: number;
  products: IProductResponse[];
}

interface IArchiveEvent {
  id: number;
  archive: IArchive;
}

interface IModalityEvent {
  modality: {
    id: number;
    name: string;
  };
}

interface ISize {
  id: number;
  size: string;
  quantity?: string;
  active: boolean;
}

interface IColorData {
  id: number;
  title: string;
  hex: string;
  files?: File[];
  archives?: IArchive[];
  sizes?: ISize[];
  quantity?: string;
  selected: boolean;
}

interface IProduct {
  id: number;
  name: string;
  archive_url?: string;
  sizes: ISize[];
  colors: IColorData[];
  files?: File[];
  quantity?: string;
}

interface IKit {
  id: number;
  products: IProduct[];
}

interface IEventResponse {
  id: number;
  name: string;
  description: string;
  date: string;
  slug: string;
  archivesEvents: IArchiveEvent[];
  type: {
    id: number;
    name: string;
  };
  address: {
    id: number;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    complement?: string;
    zip_code: string;
  };
  kits: IKitResponse[];
  modalitiesEvents: IModalityEvent[];
}

interface IEvent {
  name: string;
  description: string;
  date: string;
  type: {
    id: number;
    name: string;
  };
  address: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    complement?: string;
    zip_code: string;
  };
  archivesEvents: IArchiveEvent[];
  kits: IKit[];
  modalitiesEvents: IModalityEvent[];
}

const Event: React.FC = () => {
  const shareBtnRef = useRef<HTMLButtonElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const params = useParams<IParams>();
  const [aboutOrganizer, setAboutOrganizer] = useState(false);
  const [aboutKoro, setAboutKoro] = useState(false);
  const [event, setEvent] = useState({} as IEvent);
  const [showShare, setShowShare] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  const isPreview = useMemo(() => !!params.preview, [params.preview]);

  useEffect(() => {
    api.get<IEventResponse>(`events/${params.slug}`).then((response) => {
      const productsData = response.data.kits[0].products.map<IProduct>(
        (product) => {
          const quantity =
            product.quantities.length === 1
              ? product.quantities[0].quantity.toString()
              : undefined;

          const allSizes = ['PP', 'P', 'M', 'G', 'GG'];

          let colorsData: IColorData[] = [];
          let sizes: ISize[] = [];

          const quantitiesColors = product.quantities.find(
            (quantityData) => !!quantityData.color_id
          );
          const quantitiesSizes = product.quantities.find(
            (quantityData) => !!quantityData.size
          );
          if (quantitiesColors) {
            colorsData = product.quantities.reduce((previous, current) => {
              const {
                color_id,
                quantity: quantityAux,
                color,
                size,
                archivesProducts,
              } = current;

              let colorData = previous.find(
                (quantityData) => quantityData.id === color_id
              );

              if (!colorData) {
                colorData = {
                  id: color_id,
                  title: 'Preto',
                  hex: color?.color || '',
                  archives: [],
                  sizes: size
                    ? allSizes.map((sizeAux) => ({
                        id: 0,
                        size: sizeAux,
                        active: false,
                        quantity: '0',
                      }))
                    : undefined,
                  selected: false,
                } as IColorData;
                previous.push(colorData);
              }

              const groupSize = colorData.sizes?.find(
                (s) => s.size === size?.size
              );

              if (groupSize) {
                groupSize.active = true;
                groupSize.quantity = quantityAux.toString();
              }

              colorData.archives?.push(
                ...archivesProducts.map((archiveProduct) => ({
                  id: archiveProduct.archive.id,
                  name: archiveProduct.archive.name,
                  archive_url: archiveProduct.archive.archive_url,
                }))
              );

              return previous;
            }, [] as IColorData[]);
            sizes = colorsData[0].sizes as ISize[];
          } else if (quantitiesSizes) {
            const sizesAux = product.quantities.map((quantityData) => ({
              id: quantityData.size?.id,
              size: quantityData.size?.size,
              quantity: quantityData.quantity.toString(),
              active: true,
            }));
            sizes = allSizes.map((size) => {
              const sizeData = sizesAux.find(
                (sizeAux) => sizeAux.size === size
              );
              return {
                id: sizeData?.id || 0,
                size,
                quantity: (sizeData?.quantity || 0).toString(),
                active: !!sizeData,
              };
            });
          }

          return {
            id: product.id,
            archive_url:
              product.quantities[0].archivesProducts[0].archive.archive_url,
            name: product.name,
            quantity,
            colors: colorsData,
            sizes,
          };
        }
      );

      setEvent({
        ...response.data,
        kits: [
          {
            id: response.data.kits[0].id,
            products: productsData,
          },
        ],
      });
      setProducts(productsData);
    });
  }, [params.slug]);

  const address = useMemo(() => {
    if (Object.keys(event).length > 0) {
      return `${event.address.street} n˚ ${event.address.number},
        ${event.address.complement ? ` ${event.address.complement},` : ''} ${
        event.address.neighborhood
      } - ${event.address.city}/
        ${event.address.state}, CEP: ${event.address.zip_code}`;
    }
    return '';
  }, [event]);

  const addressMap = useMemo(() => {
    if (Object.keys(event).length > 0) {
      return `${event.address.street} n˚ ${event.address.number}, ${event.address.neighborhood} - ${event.address.city}/${event.address.state}, CEP: ${event.address.zip_code}`;
    }
    return '';
  }, [event]);

  const handleClickCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_APP_URL}/eventos/${params.slug}`
      );
      Toast.fire({
        icon: 'success',
        iconColor: '#ff6900',
        title: 'Link copiado!',
      });
    } catch (error) {
      // console.error('Erro ao copiar o texto: ', error);
    }
  }, [params.slug]);

  const checkClick = useCallback((e) => {
    const { target } = e;

    if (
      boxRef.current &&
      !boxRef.current.contains(target) &&
      shareBtnRef.current &&
      !shareBtnRef.current.contains(target)
    ) {
      setShowShare(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', checkClick);
  }, [checkClick]);

  const handleSelectColor = useCallback(
    (colorIndex, productIndex) => {
      const newProducts = products.slice();
      const { files, sizes, archives } =
        newProducts[productIndex].colors[colorIndex];

      if (files) {
        newProducts[productIndex].archive_url = URL.createObjectURL(files[0]);
      }

      if (archives) {
        newProducts[productIndex].archive_url = archives[0].archive_url;
      }

      if (sizes) {
        newProducts[productIndex].sizes = sizes;
      }

      newProducts[productIndex].colors = newProducts[productIndex].colors.map(
        (colorData, index) => ({
          ...colorData,
          selected: index === colorIndex,
        })
      );
      setProducts(newProducts);
    },
    [products]
  );

  return (
    <Container className="position-relative">
      {Object.keys(event).length > 0 && (
        <>
          <div>
            <ButtonBack
              href={`${process.env.PUBLIC_URL}/`}
              className={`btn-back ${isPreview ? 'pe-none' : ''}`}
            />
          </div>
          {/* <div>
            <button
              type="button"
              onClick={ShareButton}
              className="btns-banner receipt border-0 bg-transparent"
            >
              <img src={receipt} alt="Recibo" />
            </button>
          </div> */}
          <div className="position-relative">
            <button
              ref={shareBtnRef}
              type="button"
              className="btns-banner share border-0 bg-transparent"
              onClick={() => setShowShare((state) => !state)}
            >
              <img src={share} alt="Share" />
            </button>
            <ShareBox ref={boxRef} show={showShare}>
              <div>
                <p className="fw-semibold text-center text-primary">
                  Enviar para
                </p>
              </div>
              <div className="social-medias overflow-auto">
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className={`d-inline-block border-0 bg-transparent ${
                      isPreview ? 'pe-none' : ''
                    }`}
                    onClick={handleClickCopy}
                    disabled={isPreview}
                  >
                    <Circle color="#2D76F8">
                      <FaLink size={24} color="#fff" />
                    </Circle>
                  </button>
                  <a
                    href={`https://api.whatsapp.com/send/?text=${process.env.REACT_APP_APP_URL}/eventos/${params.slug}`}
                    className={`d-inline-block border-0 bg-transparent ${
                      isPreview ? 'pe-none' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Circle color="#29D168">
                      <FaWhatsapp size={24} color="#fff" />
                    </Circle>
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?display=popup&sdk=joey&u=${process.env.REACT_APP_APP_URL}/eventos/${params.slug}`}
                    className={`d-inline-block border-0 bg-transparent ${
                      isPreview ? 'pe-none' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Circle color="#0174FB">
                      <FaFacebookF size={24} color="#fff" />
                    </Circle>
                  </a>
                  <a
                    href={`https://t.me/share/url?url=${process.env.REACT_APP_APP_URL}/eventos/${params.slug}`}
                    className={`d-inline-block border-0 bg-transparent ${
                      isPreview ? 'pe-none' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Circle color="#2EA5D9">
                      <FaTelegramPlane size={24} color="#fff" />
                    </Circle>
                  </a>
                  <a
                    href={`https://twitter.com/intent/post?url=${process.env.REACT_APP_APP_URL}/eventos/${params.slug}`}
                    className={`d-inline-block border-0 bg-transparent ${
                      isPreview ? 'pe-none' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Circle color="#000000">
                      <FaXTwitter size={24} color="#fff" />
                    </Circle>
                  </a>
                  <a
                    href={`mailto:?body=${process.env.REACT_APP_APP_URL}/eventos/${params.slug}`}
                    className={`d-inline-block border-0 bg-transparent ${
                      isPreview ? 'pe-none' : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Circle color="#0DBEF2">
                      <FaEnvelope size={24} color="#fff" />
                    </Circle>
                  </a>
                </div>
              </div>
            </ShareBox>
          </div>
          <Carousel className="banner-slider">
            {event.archivesEvents.map((archiveEvent, index) => (
              <Banner
                key={archiveEvent.id}
                src={archiveEvent.archive.archive_url}
                first={index === 0}
              />
            ))}
          </Carousel>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="bg-white p-3 mt-negative">
                    <div className="d-flex align-items-center justify-content-between title pb-2 mb-3 border-bottom">
                      <h1 className="mb-0">{event.name}</h1>
                      <span>{event.type.name}</span>
                    </div>
                    <p className="d-flex align-items-center date-local">
                      <KrCalendar
                        size={14}
                        color="#FF6900"
                        className="address me-1"
                      />
                      {format(parseISO(event.date), 'dd/MM/yyyy')} (
                      {format(parseISO(event.date), 'EEEE', {
                        locale: ptBR,
                      })}
                      )
                    </p>
                    <p className="d-flex align-items-center date-local">
                      <KrMapMark
                        size={14}
                        color="#FF6900"
                        className="address me-1"
                      />
                      {address}
                    </p>
                    <iframe
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_KEY}&q=${addressMap}&zoom=15`}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="map"
                    />

                    <p className="mt-3">{event.description}</p>
                  </div>

                  <div className="bg-white p-3 mt-3">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h2 className="mb-0 d-flex align-items-center">
                        <KrHanger color="#292623" size={20} className="me-2" />
                        Kits
                      </h2>
                    </div>
                    <p>
                      Adquira o Kit e transforme sua participação em um marco
                      especial. Com camisetas de alta qualidade disponíveis em
                      diversos tamanhos e medalhas exclusivas, você estará
                      pronto para participar do evento com estilo e conforto.
                    </p>
                    <div className="products d-flex overflow-auto not-bar-scroll">
                      {products.map((product, index) => (
                        <div
                          key={product.id}
                          className="col-lg-6 col-xl-4 col-xxl-3 px-2 mt-3"
                        >
                          <div className="d-flex h-100">
                            <div className="kits w-100 py-4 px-3 d-flex position-relative">
                              {product.sizes?.length > 0 && (
                                <div className="d-flex flex-column w-tam">
                                  {product.sizes.map((size) => (
                                    <span
                                      key={size.id}
                                      className={`mb-2 ${
                                        size.active ? 'active' : ''
                                      }`}
                                    >
                                      {size.size}
                                    </span>
                                  ))}
                                </div>
                              )}
                              <div className="mx-auto">
                                <Img
                                  src={product.archive_url as string}
                                  className="product-photo"
                                />
                                {product.colors?.length > 0 && (
                                  <div className="d-flex justify-content-center align-items-center mt-2">
                                    {product.colors.map((color, colorIndex) => (
                                      <button
                                        key={color.id}
                                        type="button"
                                        className="border-0 bg-transparent btn-color"
                                        onClick={() =>
                                          handleSelectColor(colorIndex, index)
                                        }
                                      >
                                        <ColorBox
                                          color={color.hex}
                                          selected={color.selected}
                                        />
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-white p-3 mt-3">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h2 className="mb-0 d-flex align-items-center">
                        <KrBoxes color="#292623" size={20} className="me-2" />
                        Modalidade
                      </h2>
                    </div>
                    {event.modalitiesEvents.map((modalityEvent) => (
                      <div
                        key={modalityEvent.modality.id}
                        className="bd-mod d-flex align-items-center p-2"
                      >
                        <span>
                          <span className="text-black">
                            {modalityEvent.modality.name}
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>

                  <Link
                    to={`${process.env.PUBLIC_URL}/eventos/${params.slug}/categorias`}
                    className={`btn btn-login h6 fw-normal py-2 mt-3 mb-0 w-100 ${
                      isPreview ? 'pe-none' : ''
                    }`}
                  >
                    Inscrever-se
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bg-white px-3 pt-3 mt-3 mb-4">
                  <button
                    type="button"
                    onClick={() => setAboutOrganizer(!aboutOrganizer)}
                    className="btn-title d-flex justify-content-between align-items-center w-100 mb-3 border-0 bg-transparent"
                  >
                    Sobre o Organizador
                    <IoIosArrowDown
                      size={14}
                      color="#808080"
                      className={`${
                        aboutOrganizer ? 'rotate-arrow' : 'right-arrow'
                      }`}
                    />
                  </button>
                  <div
                    className={`${
                      aboutOrganizer
                        ? 'h-aswer-active'
                        : 'h-aswer overflow-hidden'
                    }`}
                  >
                    <p className="">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sint fuga rerum doloremque, alias cupiditate laudantium
                      voluptatibus. Consequuntur dicta repellendus eum esse
                      tempore, illo ea animi? Illum nobis modi at laborum!
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => setAboutKoro(!aboutKoro)}
                    className="btn-title d-flex justify-content-between align-items-center w-100 mb-3 border-0 bg-transparent"
                  >
                    Sobre a Koro
                    <IoIosArrowDown
                      size={14}
                      color="#808080"
                      className={`${
                        aboutKoro ? 'rotate-arrow' : 'right-arrow'
                      }`}
                    />
                  </button>
                  <div
                    className={`${
                      aboutKoro ? 'h-aswer-active' : 'h-aswer overflow-hidden'
                    }`}
                  >
                    <p>
                      A Koro é a batida do coração do esporte, um lugar onde
                      emoção e tecnologia se encontram. Nossa paixão é o
                      esporte, e nosso compromisso é conectar você aos melhores
                      eventos esportivos com facilidade e segurança. Com uma
                      plataforma criada para atletas e apaixonados, oferecemos
                      uma experiência de compra de ingressos que é tão intuitiva
                      quanto confiável.
                    </p>
                    <p className="pb-3 mb-0">
                      Na Koro, utilizamos dados de forma inteligente para criar
                      conexões únicas, permitindo que você descubra e participe
                      de competições que realmente fazem seu coração pulsar.
                      Seja na quadra, no campo, na areia ou no sofá de casa, a
                      Koro faz com que cada momento esportivo seja uma
                      experiência compartilhada de emoção e união.
                    </p>
                  </div>
                  {/* <button
                    type="button"
                    onClick={() => setFaq(!faq)}
                    className="btn-title d-flex justify-content-between align-items-center w-100 mb-3 border-0 bg-transparent"
                  >
                    F.A.Q
                    <IoIosArrowDown
                      size={14}
                      color="#808080"
                      className={`${faq ? 'rotate-arrow' : 'right-arrow'}`}
                    />
                  </button>
                  <div
                    className={`${
                      faq ? 'h-aswer-active' : 'h-aswer overflow-hidden'
                    }`}
                  >
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sint fuga rerum doloremque, alias cupiditate laudantium
                      voluptatibus. Consequuntur dicta repellendus eum esse
                      tempore, illo ea animi? Illum nobis modi at laborum!
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Event;
