import styled from 'styled-components';

export const Container = styled.div`
  .bg {
    border-radius: 8px;
    background: #fff;
  }

  label span {
    color: #292623;
    font-size: 12px;
  }

  .bd-input {
    border: 1px solid #e1e1e1;
    height: 37px;
    border-radius: 4px;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #8c8c8c;
      transition-duration: 0.2s;
      padding: 0;

      ::placeholder {
        color: #8c8c8c;
      }
    }
  }

  svg:not(.calendar) {
    display: none;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }
  .check span {
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 400;
  }
`;
