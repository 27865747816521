import React from 'react';
import { IProps } from './interfaces';

const KrCamera: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.25 5.61001H23.12V5.44001C23.12 3.95001 21.91 2.73001 20.41 2.73001H11.58C10.09 2.73001 8.86999 3.94001 8.86999 5.44001V5.61001H2.74999C1.25999 5.61001 0.0399933 6.82001 0.0399933 8.32001V26.57C0.0399933 28.06 1.24999 29.28 2.74999 29.28H29.25C30.74 29.28 31.96 28.07 31.96 26.57V8.32001C31.96 6.83001 30.75 5.61001 29.25 5.61001ZM30.16 26.56C30.16 27.06 29.75 27.47 29.25 27.47H2.74999C2.24999 27.47 1.83999 27.06 1.83999 26.56V8.32001C1.83999 7.82001 2.24999 7.41001 2.74999 7.41001H9.80999C10.06 7.41001 10.31 7.30001 10.48 7.11001C10.65 6.92001 10.73 6.67001 10.71 6.42001L10.69 5.43001C10.69 4.93001 11.1 4.52001 11.6 4.52001H20.43C20.93 4.52001 21.34 4.93001 21.34 5.43001V6.50001C21.34 7.00001 21.74 7.40001 22.24 7.40001H29.27C29.77 7.40001 30.18 7.81001 30.18 8.31001V26.56H30.16Z"
        fill={color}
      />
      <path
        d="M16 11.29C12.29 11.29 9.25999 14.31 9.25999 18.03C9.25999 21.75 12.28 24.76 16 24.76C19.72 24.76 22.74 21.74 22.74 18.03C22.74 14.32 19.72 11.29 16 11.29ZM16 22.96C13.28 22.96 11.06 20.75 11.06 18.02C11.06 15.29 13.27 13.08 16 13.08C18.73 13.08 20.94 15.29 20.94 18.02C20.94 20.75 18.73 22.96 16 22.96Z"
        fill={color}
      />
      <path
        d="M5.85 12.69C6.7447 12.69 7.47 11.9647 7.47 11.07C7.47 10.1753 6.7447 9.45001 5.85 9.45001C4.95529 9.45001 4.23 10.1753 4.23 11.07C4.23 11.9647 4.95529 12.69 5.85 12.69Z"
        fill={color}
      />
    </svg>
  );
};

export default KrCamera;
