/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useRef } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import SliderSlick, { Settings } from 'react-slick';

import { Container, ButtonLeft, ButtonRight } from './styles';

interface ICarousel {
  className?: string;
  children?: any;
}

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonLeft
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowBack size={24} />
      </div>
    </ButtonLeft>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  const disabled = useMemo(() => {
    if (className) {
      if (className.match('slick-disabled')) {
        return true;
      }
      return false;
    }
    return false;
  }, [className]);

  return (
    <ButtonRight
      type="button"
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <IoIosArrowForward size={24} />
      </div>
    </ButtonRight>
  );
};

const Carousel: React.FC<ICarousel> = ({ className, children }) => {
  const sliderSlickRef = useRef<SliderSlick>(null);

  const settings: Settings = useMemo(
    () => ({
      infinite: true,
      dots: true,
      slidesToShow: 1,
      centerPadding: '0px',
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: false,
      touchMove: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }),
    []
  );

  return (
    <Container className={className}>
      <SliderSlick ref={sliderSlickRef} {...settings}>
        {children}
      </SliderSlick>
    </Container>
  );
};

export default Carousel;
