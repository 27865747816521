import React from 'react';
import { IProps } from './interfaces';

const KrCreditCard: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.28 4.91H2.78001C1.28001 4.91 0.0700073 6.12 0.0700073 7.61V25.86C0.0700073 27.35 1.28001 28.57 2.78001 28.57H29.28C30.77 28.57 31.99 27.36 31.99 25.86V7.61C31.99 6.12 30.78 4.9 29.28 4.9V4.91ZM2.78001 6.71H29.28C29.78 6.71 30.19 7.12 30.19 7.62V11.61H1.87001V7.62C1.87001 7.12 2.28001 6.71 2.78001 6.71ZM29.28 26.76H2.78001C2.28001 26.76 1.87001 26.35 1.87001 25.86V13.41H30.18V25.86C30.18 26.36 29.77 26.76 29.27 26.76H29.28Z"
        fill={color}
      />
      <path
        d="M27.26 21.52H22C21.5 21.52 21.1 21.92 21.1 22.42C21.1 22.92 21.5 23.32 22 23.32H27.26C27.76 23.32 28.16 22.92 28.16 22.42C28.16 21.92 27.76 21.52 27.26 21.52Z"
        fill={color}
      />
    </svg>
  );
};

export default KrCreditCard;
