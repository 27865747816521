import React, { useCallback } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

interface IProps {
  className?: string;
  href?: string;
}

const ButtonBack: React.FC<IProps> = ({ className, href }) => {
  const history = useHistory();

  const handleBack = useCallback(() => {
    if (href) {
      history.push(href);
    } else {
      history.goBack();
    }
  }, [history, href]);

  return (
    <Container
      type="button"
      onClick={handleBack}
      className={`d-flex align-items-center bg-transparent border-0 ${className}`}
    >
      <IoIosArrowBack color="#878787" size={12} className="me-2" /> Voltar
    </Container>
  );
};

export default ButtonBack;
