import React from 'react';
import { IProps } from './interfaces';

const KrShoppingCart: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.02 5.59H24.47C24.04 5.59 23.68 5.89 23.58 6.3L19.95 22.6H4.77L2.17 11.47H19.49C19.99 11.47 20.4 11.06 20.4 10.56C20.4 10.06 19.99 9.65 19.49 9.65H1.02C0.740002 9.65 0.480002 9.78 0.310002 9.99C0.140002 10.21 0.0700016 10.49 0.140002 10.76L3.16 23.71C3.26 24.12 3.62 24.41 4.05 24.41H20.68C21.11 24.41 21.47 24.11 21.57 23.7L25.2 7.4H31.02C31.52 7.4 31.93 6.99 31.93 6.49C31.93 5.99 31.52 5.58 31.02 5.58V5.59Z"
        fill={color}
      />
      <path
        d="M7.2 25.83C5.93 25.83 4.9 26.86 4.9 28.13C4.9 29.4 5.93 30.43 7.2 30.43C8.47 30.43 9.5 29.4 9.5 28.13C9.5 26.86 8.47 25.83 7.2 25.83Z"
        fill={color}
      />
      <path
        d="M17.55 25.83C16.28 25.83 15.25 26.86 15.25 28.13C15.25 29.4 16.28 30.43 17.55 30.43C18.82 30.43 19.85 29.4 19.85 28.13C19.85 26.86 18.82 25.83 17.55 25.83Z"
        fill={color}
      />
    </svg>
  );
};

export default KrShoppingCart;
