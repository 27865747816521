import React, { useCallback, useEffect, useState } from 'react';

import { NavLink, useHistory, useParams } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { addHours, format, parseISO } from 'date-fns';
import { BsFillCameraFill } from 'react-icons/bs';
import { Bar, Container, Menu, Progress, TopRide } from './styles';
import AvatarProfile from '~/components/Avatar';
import bronze from '~/assets/icons/medalha-bronze.svg';
import silver from '~/assets/icons/medalha-prata.svg';
import gold from '~/assets/icons/medalha-ouro.svg';
import platinum from '~/assets/icons/medalha-platina.svg';
import diamond from '~/assets/icons/medalha-diamante.svg';
import {
  KrCake,
  KrCalendar,
  KrCamera,
  KrInfo,
  KrMapMark,
  KrSignout,
  KrVerify,
} from '~/components/KoroIcons';
import MyProfile from './MyProfile';
import Footer from '~/components/Footer';

import level from '~/assets/defaults/level.svg';
import medalGroup from '~/assets/defaults/medal-group-new.svg';
import Purchases from './Purchases';
import Security from './Security';
import Support from './Support';
import AddCards from '~/components/AddCards';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import avatarDefault from '~/assets/defaults/avatar.svg';

interface IParams {
  slug?: string;
}

interface IOrderResponse {
  data: IOrder[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IOrder {
  id: number;
  code: string;
  service_fee: string;
  processing_fee: string;
  discount: string;
  total: number;
  installments: number;
  status: string;
  created_at: string;
  quantity: number;
  items: IItem[];
}

interface IItem {
  id: number;
  quantity: number;
  batch_category_id: number;
  ticket: {
    modality: {
      name: string;
    };
  };
  price: number;
  batchCategory: {
    price: string;
    batch: {
      event: {
        name: string;
      };
    };
    modality: {
      name: string;
    };
    category: {
      name: string;
    };
  };
}

const Profile: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { user, updateUser, signOut } = useAuth();
  const [info, setInfo] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [reloadCards, setReloadCards] = useState(1);
  const [avatar, setAvatar] = useState('');
  const [medal, setMedal] = useState('');
  const [medalText, setMedalText] = useState('');
  const [progress, setProgress] = useState(0);

  const [orders, setOrders] = useState<IOrder[]>([]);

  const totalQuantityPaid = orders.reduce((total, order) => {
    if (order.status === 'Pago') {
      return total + order.items.length;
    }
    return total;
  }, 0);

  useEffect(() => {
    if (!params.slug) {
      history.push(`${process.env.PUBLIC_URL}/perfil/meus-dados`);
    }
  }, [history, params.slug]);

  useEffect(() => {
    if (totalQuantityPaid === 0) {
      setMedal(bronze);
      setMedalText('bronze');
      setProgress(0);
    } else if (totalQuantityPaid < 6) {
      setMedal(bronze);
      setMedalText('bronze');
      setProgress((totalQuantityPaid / 5) * 100);
    } else if (totalQuantityPaid < 16) {
      setMedal(silver);
      setMedalText('prata');
      setProgress(((totalQuantityPaid - 5) / 10) * 100);
    } else if (totalQuantityPaid < 31) {
      setMedal(gold);
      setMedalText('ouro');
      setProgress(((totalQuantityPaid - 15) / 15) * 100);
    } else if (totalQuantityPaid < 51) {
      setMedal(diamond);
      setMedalText('diamante');
      setProgress(((totalQuantityPaid - 30) / 20) * 100);
    } else {
      setMedal(platinum);
      setMedalText('platina');
      setProgress(100);
    }
  }, [totalQuantityPaid]);

  const handleShowAddCard = useCallback(() => {
    setShowAddCard(true);
  }, []);

  const handleClickLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleSucceededAddCard = useCallback(() => {
    setReloadCards((state) => state + 1);
    setShowAddCard(false);
  }, []);

  const handleChangeAvatar = useCallback(
    async (e) => {
      try {
        setAvatar(URL.createObjectURL(e.target.files[0]));
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('folder', 'avatars');

        const response = await api.post('archives', formData);

        await api.patch('additional-informations/avatars', {
          avatar_id: response.data.id,
        });

        updateUser({
          ...user,
          additionalInformation: {
            ...user.additionalInformation,
            avatar: {
              archive_url: response.data.archive_url,
            },
          },
        });
      } catch (error) {
        // console.log(error);
      }
    },
    [updateUser, user]
  );

  const handleLoadOrders = useCallback(
    async (
      currentPage = 1,
      accumulatedOrders: IOrder[] = []
    ): Promise<void> => {
      const response = await api.get<IOrderResponse>('orders/purchases', {
        params: {
          page: currentPage,
        },
      });

      const newOrders = [...accumulatedOrders, ...response.data.data];

      if (currentPage < response.data.pages) {
        return handleLoadOrders(currentPage + 1, newOrders);
      }
      return setOrders(newOrders);
    },
    []
  );

  useEffect(() => {
    handleLoadOrders();
  }, [handleLoadOrders]);

  return (
    <Container>
      {!showAddCard ? (
        <div className="bg-all">
          <>
            <TopRide>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-6 d-flex align-items-center">
                    <label className="avatar">
                      <AvatarProfile
                        src={
                          user.additionalInformation.avatar?.archive_url ||
                          avatarDefault
                        }
                      />
                      <div className="btn-photo">
                        <KrCamera size={24} color="#fff" />
                      </div>
                      <input
                        type="file"
                        className="d-none"
                        onChange={handleChangeAvatar}
                      />
                    </label>
                  </div>
                  <div className="col-5 text-end">
                    <div className="position-relative d-inline-block">
                      <button
                        type="button"
                        onClick={() => setInfo(!info)}
                        className="bg-transparent border-0"
                      >
                        <span className="d-block text-end mb-n2">
                          <KrInfo color="#ffffff75" size={16} />
                        </span>
                        <img
                          src={medal}
                          alt={`Medalha de ${medalText}`}
                          className="medal mb-2"
                        />
                      </button>
                      <span className="d-block text-center text-capitalize text-white">
                        {medalText}
                      </span>
                      {info && (
                        <div className="notification text-start">
                          <div className="triangle" />

                          <p>
                            Nosso sistema de medalhas reflete o nível do seu
                            perfil. Quanto mais completo for seu perfil e mais
                            compras fizer, melhor será sua medalha. Medalhas
                            melhores identificam atletas ativos e geram
                            benefícios!
                          </p>
                          <div className="imgs text-center my-2">
                            <img src={medalGroup} alt="Medal" />
                          </div>
                          <p>
                            Nossas medalhas vão de bronze, recebida ao se
                            registrar, até diamante, para os top atletas!
                          </p>
                          <div className="d-flex ">
                            <button
                              type="button"
                              onClick={() => setInfo(false)}
                              className="mx-auto btn btn-login h6 fw-normal py-2 mt-4"
                            >
                              Entendido!
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <Bar className="ms-auto">
                      <Progress width={progress} />
                    </Bar>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-11">
                    <h2 className="h6 text-white mt-3">
                      {user.name}
                      {user.additionalInformation.verified && (
                        <KrVerify
                          color="#fff"
                          secondaryColor="#ff7a00"
                          size={16}
                          className="ms-2"
                        />
                      )}
                    </h2>
                    <p className="mb-2 d-flex align-items-center">
                      <KrMapMark color="#fff" size={12} className="me-1" />{' '}
                      {user.additionalInformation.address
                        ? `${user.additionalInformation.address.city}, ${user.additionalInformation.address.state}`
                        : '-'}
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">
                        <span>
                          <KrCake
                            color="#fff"
                            size={12}
                            className="mb-1 me-1"
                          />
                          <span>
                            {user.additionalInformation.birthdate
                              ? format(
                                  addHours(
                                    parseISO(
                                      user.additionalInformation.birthdate
                                    ),
                                    3
                                  ),
                                  'dd/MM/yyyy'
                                )
                              : '-'}
                          </span>
                        </span>
                        <span>
                          <KrCalendar
                            color="#fff"
                            size={12}
                            className="mb-1 me-1 ms-4"
                          />
                          <span>
                            Ingressou em{' '}
                            {format(
                              parseISO(user.additionalInformation.created_at),
                              'yyyy'
                            )}
                          </span>
                        </span>
                      </p>
                      <button
                        type="button"
                        className="btn-signout border-0 bg-transparent"
                        onClick={handleClickLogout}
                      >
                        Sair
                        <KrSignout color="#fff" size={12} className="ms-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TopRide>
            <Menu>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="bg d-flex justify-content-between px-3">
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/perfil/meus-dados`}
                        activeClassName="active"
                      >
                        Meus Dados
                      </NavLink>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/perfil/compras`}
                        activeClassName="active"
                      >
                        Compras
                      </NavLink>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/perfil/seguranca`}
                        activeClassName="active"
                      >
                        Segurança
                      </NavLink>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/perfil/suporte`}
                        activeClassName="active"
                      >
                        Suporte
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Menu>
            {params.slug === 'meus-dados' && <MyProfile />}
            {params.slug === 'compras' && <Purchases />}
            {params.slug === 'seguranca' && (
              <Security
                handleShowAddCard={handleShowAddCard}
                reloadCards={reloadCards}
              />
            )}
            {params.slug === 'suporte' && <Support />}
          </>
        </div>
      ) : (
        <AddCards saveCard onSucceededAddCard={handleSucceededAddCard}>
          <button
            className="d-flex align-items-center bg-transparent border-0 mt-3 btn-children-back"
            type="button"
            onClick={() => setShowAddCard(false)}
          >
            <IoIosArrowBack color="#878787" size={12} className="me-2" /> Voltar
          </button>
        </AddCards>
      )}
      <Footer />
    </Container>
  );
};

export default Profile;
