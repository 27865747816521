/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useState, useContext } from 'react';
import Swal from 'sweetalert2';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import avatar from '~/assets/defaults/avatar.svg';

export interface IUser {
  id: number;
  name: string;
  email: string;
  additionalInformation: {
    id: number;
    document?: string;
    phone?: string;
    birthdate?: string;
    gender?: string;
    verified: boolean;
    created_at: string;
    address?: {
      id?: number;
      street: string;
      number?: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      complement?: string;
      zip_code: string;
    };
    avatar?: {
      archive_url: string;
    };
  };
}

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface IGoogleData {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  authuser: string;
  prompt: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInGoogle(googleData: IGoogleData, inPurchase?: boolean): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Koro:token');
    const user = localStorage.getItem('@Koro:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post<AuthState>(
        `users/sessions`,
        {
          email,
          password,
        },
        {
          params: {
            environment: 'Atleta',
          },
        }
      );

      const { token, user } = response.data;

      localStorage.setItem('@Koro:token', token);
      localStorage.setItem(
        '@Koro:user',
        JSON.stringify({
          ...user,
          additionalInformation: {
            ...user.additionalInformation,
            avatar: user.additionalInformation.avatar || {
              archive_url: avatar,
            },
          },
        })
      );

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({
        token,
        user,
      });
    } catch (error) {
      Swal.fire('Opss...', 'Ocorreu um erro tente novamente', 'error');
    }
  }, []);

  const signInGoogle = useCallback(
    async (formData: IGoogleData, inPurchase?: boolean) => {
      try {
        const response = await api.post<AuthState>(
          `users/sessions/google`,
          formData,
          {
            params: {
              environment: 'Atleta',
            },
          }
        );

        const { token, user } = response.data;

        localStorage.setItem('@Koro:token', token);
        localStorage.setItem(
          '@Koro:user',
          JSON.stringify({
            ...user,
            additionalInformation: {
              ...user.additionalInformation,
              avatar: user.additionalInformation.avatar || {
                archive_url: avatar,
              },
            },
          })
        );

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({
          token,
          user,
        });
      } catch (error) {
        const err = error as any;
        if (err.response.data.message === 'User not found') {
          const response = await axios.get(
            `https://www.googleapis.com/oauth2/v3/userinfo`,
            {
              headers: {
                Authorization: `Bearer ${formData.access_token}`,
              },
            }
          );

          if (!inPurchase) {
            history.push(`${process.env.PUBLIC_URL}/cadastro`, {
              userData: response.data,
            });
          } else {
            const errorData = new Error('User not found') as any;

            errorData.data = response.data;

            throw errorData;
          }
        } else {
          Swal.fire('Opss...', 'Ocorreu um erro tente novamente', 'error');
        }
      }
    },
    [history]
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Koro:token');
    localStorage.removeItem('@Koro:user');

    // await axios.get(`${process.env.REACT_APP_OLD_URL}/auth/auth/logout`);

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@Koro:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser, signInGoogle }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
