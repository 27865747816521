import styled from 'styled-components';
import banner from '~/assets/banners/banner-verificar.png';

export const Container = styled.div`
  min-height: 100vh;
  h1 {
    color: #ff6900;
    font-size: 26px;
  }

  h2 {
    color: #ff6900;
    font-family: 'Poppins';
  }

  p {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
  }

  .banner {
    background-image: url(${banner});
    height: 100px;
    width: 100%;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .btn-resend {
    color: #ff6900;
    font-family: 'Roboto';
    background-color: transparent;
    font-size: 14px;
    border: none;
    line-height: 0;
  }

  .bd-input {
    border: 1px solid transparent;
    width: 180px !important;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #8c8c8c;
      transition-duration: 0.2s;

      ::placeholder {
        color: #8c8c8c;
      }
    }
  }
`;
