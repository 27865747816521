import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Container, Menu } from './styles';
import ButtonBack from '~/components/ButtonBack';
import Footer from '~/components/Footer';
import InputSearch from '~/components/InputSearch';

import Nexts from './Nexts';
import Realized from './Realized';
import Canceled from './Canceled';

interface ILocation {
  category: string;
}

const MyTickets: React.FC = () => {
  const location = useLocation<ILocation>();

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="bg-white px-3 py-3 mt-3">
              <ButtonBack className="" />

              <h1 className="mt-3 mb-3">Meus Ingressos</h1>
              <div className="d-flex">
                <InputSearch className="py-1 flex-auto input-search" filter />
              </div>
            </div>
          </div>
          <div className="col-12">
            <Menu>
              <div className="bg d-flex justify-content-between px-3 mt-3">
                <Link
                  to={`${process.env.PUBLIC_URL}/meus-ingressos/proximos`}
                  className={`${
                    location.pathname === '/meus-ingressos/proximos' && 'active'
                  }`}
                >
                  Próximos
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/meus-ingressos/realizados`}
                  className={`${
                    location.pathname === '/meus-ingressos/realizados' &&
                    'active'
                  }`}
                >
                  Realizados
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/meus-ingressos/cancelados`}
                  className={`${
                    location.pathname === '/meus-ingressos/cancelados' &&
                    'active'
                  }`}
                >
                  Cancelados
                </Link>
              </div>
            </Menu>
          </div>
          <div className="col-12 px-0">
            {location.pathname === '/meus-ingressos/proximos' && <Nexts />}
            {location.pathname === '/meus-ingressos/realizados' && <Realized />}
            {location.pathname === '/meus-ingressos/cancelados' && <Canceled />}
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default MyTickets;
