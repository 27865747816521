import React from 'react';
import { IProps } from './interfaces';

const KrCalendar: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1882)">
        <path
          d="M29.2 2.96H26.88V1.73C26.88 0.809999 26.13 0.0499992 25.2 0.0499992C24.27 0.0499992 23.52 0.799999 23.52 1.73V2.96H8.44V1.73C8.45 0.799999 7.7 0.0499992 6.77 0.0499992C5.84 0.0499992 5.09 0.799999 5.09 1.73V2.96H2.77C1.31 2.96 0.110001 4.15 0.110001 5.63V28.74C0.110001 30.21 1.31 31.41 2.78 31.41H29.2C30.67 31.41 31.87 30.21 31.87 28.74V5.63C31.87 4.16 30.67 2.96 29.2 2.96ZM24.73 1.73C24.73 1.47 24.94 1.25 25.21 1.25C25.48 1.25 25.69 1.46 25.69 1.73V2.96H24.74V1.73H24.73ZM6.3 1.73C6.3 1.47 6.51 1.25 6.78 1.25C7.05 1.25 7.26 1.46 7.26 1.73V2.96H6.31V1.73H6.3ZM2.78 4.7H29.2C29.71 4.7 30.13 5.12 30.13 5.63V8.72H1.85V5.63C1.85 5.12 2.27 4.7 2.78 4.7ZM29.2 29.67H2.78C2.27 29.67 1.85 29.25 1.85 28.74V9.92H30.13V28.74C30.13 29.25 29.71 29.67 29.2 29.67Z"
          fill={color}
        />
        <path
          d="M8.02 12.27H5.6C4.61 12.27 3.8 13.08 3.8 14.07V16.49C3.8 17.48 4.61 18.29 5.6 18.29H8.02C9.01 18.29 9.82 17.48 9.82 16.49V14.07C9.82 13.08 9.01 12.27 8.02 12.27ZM8.62 16.5C8.62 16.83 8.35 17.1 8.02 17.1H5.6C5.27 17.1 5 16.83 5 16.5V14.08C5 13.75 5.27 13.48 5.6 13.48H8.02C8.35 13.48 8.62 13.75 8.62 14.08V16.5Z"
          fill={color}
        />
        <path
          d="M17.24 12.27H14.82C13.83 12.27 13.02 13.08 13.02 14.07V16.49C13.02 17.48 13.83 18.29 14.82 18.29H17.24C18.23 18.29 19.04 17.48 19.04 16.49V14.07C19.04 13.08 18.23 12.27 17.24 12.27ZM17.84 16.5C17.84 16.83 17.57 17.1 17.24 17.1H14.82C14.49 17.1 14.22 16.83 14.22 16.5V14.08C14.22 13.75 14.49 13.48 14.82 13.48H17.24C17.57 13.48 17.84 13.75 17.84 14.08V16.5Z"
          fill={color}
        />
        <path
          d="M26.46 12.27H24.04C23.05 12.27 22.24 13.08 22.24 14.07V16.49C22.24 17.48 23.05 18.29 24.04 18.29H26.46C27.45 18.29 28.26 17.48 28.26 16.49V14.07C28.26 13.08 27.45 12.27 26.46 12.27ZM27.05 16.5C27.05 16.83 26.78 17.1 26.45 17.1H24.03C23.7 17.1 23.43 16.83 23.43 16.5V14.08C23.43 13.75 23.7 13.48 24.03 13.48H26.45C26.78 13.48 27.05 13.75 27.05 14.08V16.5Z"
          fill={color}
        />
        <path
          d="M8.02 20.57H5.6C4.61 20.57 3.8 21.38 3.8 22.37V24.79C3.8 25.78 4.61 26.59 5.6 26.59H8.02C9.01 26.59 9.82 25.78 9.82 24.79V22.37C9.82 21.38 9.01 20.57 8.02 20.57ZM8.62 24.79C8.62 25.12 8.35 25.39 8.02 25.39H5.6C5.27 25.39 5 25.12 5 24.79V22.37C5 22.04 5.27 21.77 5.6 21.77H8.02C8.35 21.77 8.62 22.04 8.62 22.37V24.79Z"
          fill={color}
        />
        <path
          d="M17.24 20.57H14.82C13.83 20.57 13.02 21.38 13.02 22.37V24.79C13.02 25.78 13.83 26.59 14.82 26.59H17.24C18.23 26.59 19.04 25.78 19.04 24.79V22.37C19.04 21.38 18.23 20.57 17.24 20.57ZM17.84 24.79C17.84 25.12 17.57 25.39 17.24 25.39H14.82C14.49 25.39 14.22 25.12 14.22 24.79V22.37C14.22 22.04 14.49 21.77 14.82 21.77H17.24C17.57 21.77 17.84 22.04 17.84 22.37V24.79Z"
          fill={color}
        />
        <path
          d="M26.46 20.57H24.04C23.05 20.57 22.24 21.38 22.24 22.37V24.79C22.24 25.78 23.05 26.59 24.04 26.59H26.46C27.45 26.59 28.26 25.78 28.26 24.79V22.37C28.26 21.38 27.45 20.57 26.46 20.57ZM27.05 24.79C27.05 25.12 26.78 25.39 26.45 25.39H24.03C23.7 25.39 23.43 25.12 23.43 24.79V22.37C23.43 22.04 23.7 21.77 24.03 21.77H26.45C26.78 21.77 27.05 22.04 27.05 22.37V24.79Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1882">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrCalendar;
