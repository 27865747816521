import React from 'react';
import { IProps } from './interfaces';

const KrPass: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1850)">
        <path
          d="M25.26 8.82H6.74C3.02 8.82 0 11.84 0 15.56V16.57C0 20.29 3.02 23.31 6.74 23.31H25.27C28.99 23.31 32.01 20.29 32.01 16.57V15.56C32.01 11.84 28.99 8.82 25.27 8.82H25.26ZM30.2 16.57C30.2 19.29 27.99 21.5 25.26 21.5H6.74C4.02 21.5 1.8 19.29 1.8 16.57V15.56C1.8 12.84 4.01 10.62 6.74 10.62H25.27C27.99 10.62 30.21 12.83 30.21 15.56V16.57H30.2Z"
          fill={color}
        />
        <path
          d="M9.24 14.41C9.06 14.13 8.69 14.05 8.41 14.23L7.04 15.1V13.47C7.04 13.14 6.77 12.87 6.44 12.87C6.11 12.87 5.84 13.14 5.84 13.47V15.02L4.57 14.12C4.3 13.93 3.93 13.99 3.73 14.26C3.54 14.53 3.6 14.9 3.87 15.1L5.36 16.16L3.82 17.14C3.54 17.32 3.46 17.69 3.64 17.97C3.75 18.15 3.95 18.25 4.15 18.25C4.26 18.25 4.37 18.22 4.47 18.16L5.84 17.29V18.92C5.84 19.25 6.11 19.52 6.44 19.52C6.77 19.52 7.04 19.25 7.04 18.92V17.37L8.31 18.27C8.42 18.35 8.54 18.38 8.66 18.38C8.85 18.38 9.03 18.29 9.15 18.13C9.34 17.86 9.28 17.49 9.01 17.29L7.52 16.23L9.06 15.25C9.34 15.07 9.42 14.7 9.24 14.42V14.41Z"
          fill={color}
        />
        <path
          d="M18.8 14.41C18.62 14.13 18.25 14.05 17.97 14.23L16.6 15.1V13.47C16.6 13.14 16.33 12.87 16 12.87C15.67 12.87 15.4 13.14 15.4 13.47V15.02L14.13 14.12C13.86 13.93 13.49 13.99 13.29 14.26C13.1 14.53 13.16 14.9 13.43 15.1L14.92 16.16L13.38 17.14C13.1 17.32 13.02 17.69 13.2 17.97C13.31 18.15 13.51 18.25 13.71 18.25C13.82 18.25 13.93 18.22 14.03 18.16L15.4 17.29V18.92C15.4 19.25 15.67 19.52 16 19.52C16.33 19.52 16.6 19.25 16.6 18.92V17.37L17.87 18.27C17.98 18.35 18.1 18.38 18.22 18.38C18.41 18.38 18.59 18.29 18.71 18.13C18.9 17.86 18.84 17.49 18.57 17.29L17.08 16.23L18.62 15.25C18.9 15.07 18.98 14.7 18.8 14.42V14.41Z"
          fill={color}
        />
        <path
          d="M28.36 14.15C28.18 13.87 27.81 13.79 27.53 13.97L26.16 14.84V13.21C26.16 12.88 25.89 12.61 25.56 12.61C25.23 12.61 24.96 12.88 24.96 13.21V14.76L23.69 13.86C23.42 13.67 23.05 13.73 22.85 14C22.66 14.27 22.72 14.64 22.99 14.84L24.48 15.9L22.94 16.88C22.66 17.06 22.58 17.43 22.76 17.71C22.87 17.89 23.07 17.99 23.27 17.99C23.38 17.99 23.49 17.96 23.59 17.9L24.96 17.03V18.66C24.96 18.99 25.23 19.26 25.56 19.26C25.89 19.26 26.16 18.99 26.16 18.66V17.11L27.43 18.01C27.54 18.09 27.66 18.12 27.78 18.12C27.97 18.12 28.15 18.03 28.27 17.87C28.46 17.6 28.4 17.23 28.13 17.03L26.64 15.97L28.18 14.99C28.46 14.81 28.54 14.44 28.36 14.16V14.15Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1850">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrPass;
