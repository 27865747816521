import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  p {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }
`;

export const SearchInput = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  padding: 8px;

  input {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    background-color: transparent;
  }
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .title {
    color: #000;
    font-family: 'Roboto';
  }

  h4 {
    color: #000;
    font-family: 'Roboto';
  }

  span {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }

  p {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }

  .btn-filter {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }
`;
