import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  .pincode-input-container {
    display: flex;
    justify-content: center;
  }
  input.pincode-input-text {
    //opacity: 0.8;
    border: 1.5px solid #e1e1e1 !important;
    box-sizing: border-box;
    border-radius: 8px;
    width: 40px !important;
    height: 60px !important;
    flex: 1;
    background: transparent;
    border: 0;
    color: #8c8c8c;
    transition-duration: 0.2s;
    margin: 0 5px !important;

    ::placeholder {
      color: #efefef;
    }

    :focus {
      border: 1.5px solid #8c8c8c !important;
    }
  }

  svg {
    margin-right: 16px;
    color: #666360;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      input.pincode-input-text {
        border-color: #c53030 !important;
      }
    `}
`;
