import React, { useState, useCallback, useEffect } from 'react';
import { ReactCreditCard } from '@repay/react-credit-card';
import '@repay/react-credit-card/dist/react-credit-card.css';

import axios from 'axios';
import { addHours, format, parseISO } from 'date-fns';
import { Container } from './styles';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';
import InputCheckbox from '../InputCheckbox';
import { useAuth } from '~/hooks/Auth';
import InputRadio from '../InputRadio';

interface ICreditCard {
  id?: string;
  name: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
  flag: string;
}

interface creditCardProps {
  initialValue?: ICreditCard;
  className?: string;
  saveCard?: boolean;
  setSave?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAddress {
  zipcode?: string;
  street?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  complement?: string;
}

interface IData {
  email?: string;
  document?: string;
  phone?: string;
  birthdate?: string;
  gender?: string;
}

const CreditCard: React.FC<creditCardProps> = ({
  initialValue,
  className,
  saveCard,
  setSave,
}) => {
  const { user } = useAuth();
  const [sameAddress, setSameAddress] = useState(false);
  const [myData, setMyData] = useState(true);
  const [check, setCheck] = useState(false);
  const [values, setValues] = useState({
    name: initialValue ? initialValue.name : '',
    cardNumber: initialValue ? initialValue.cardNumber : '',
    expiration: initialValue ? initialValue.expiration : '',
    cvc: initialValue ? initialValue.cvc : '',
    flag: initialValue ? initialValue.flag : '',
  });
  const [cvvLength, setCvvLength] = useState(3);
  const [address, setAddress] = useState({} as IAddress);
  const [data, setData] = useState({} as IData);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      const noSpaceValue = value.replace(/ /g, '');
      setValues((v) => ({
        ...v,
        [name]: name === 'cardNumber' ? noSpaceValue : value,
      }));
      if (name === 'cardNumber') {
        const ReactCreditCardElement =
          document.getElementsByClassName('ReactCreditCard')[0];
        let flag = ReactCreditCardElement.classList[1].replace(
          'ReactCreditCard--',
          ''
        );

        flag = flag.charAt(0).toUpperCase() + flag.slice(1);
        setValues((v) => ({ ...v, flag: flag === 'Unknown' ? '' : flag }));
      }
    },
    [setValues]
  );

  const handleChangeCard = useCallback(() => {
    const cardElement = document.getElementById('card');
    if (
      cardElement &&
      cardElement.children[0].children[0].children[0].classList[1].replace(
        'ReactCreditCard--',
        ''
      ) === 'amex'
    ) {
      setCvvLength(4);
    } else {
      setCvvLength(3);
    }
  }, []);

  const [focused, setFocus] = useState<
    'number' | 'cvc' | 'expiration' | 'name' | undefined
  >(undefined);
  const handleFocus = useCallback(
    (event) => {
      setFocus(event.target.name || event.target.placeholder.toLowerCase());
    },
    [setFocus]
  );

  const handleBlur = useCallback(() => setFocus(undefined), [setFocus]);

  const handleChangeSaveCard = useCallback(() => {
    setCheck(!check);
    if (setSave) {
      setSave(!check);
    }
  }, [check, setSave]);

  useEffect(() => {
    setAddress({
      zipcode: user.additionalInformation.address?.zip_code,
      street: user.additionalInformation.address?.street,
      neighborhood: user.additionalInformation.address?.neighborhood,
      city: user.additionalInformation.address?.city,
      state: user.additionalInformation.address?.state,
      number: user.additionalInformation.address?.number,
      complement: user.additionalInformation.address?.complement,
    });
  }, [sameAddress, user.additionalInformation.address]);

  useEffect(() => {
    setData(
      myData
        ? {
            email: user.email,
            document: user.additionalInformation.document,
            phone: user.additionalInformation.phone,
            birthdate: user.additionalInformation.birthdate
              ? format(
                  addHours(parseISO(user.additionalInformation.birthdate), 3),
                  'yyyy-MM-dd'
                )
              : undefined,
            gender: user.additionalInformation.gender,
          }
        : ({} as IData)
    );
  }, [myData, user]);

  const handleChangeMyData = useCallback(() => {
    setMyData(!myData);
  }, [myData]);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setAddress({
        zipcode: value,
        street: response.data.logradouro,
        neighborhood: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf,
        complement: response.data.complement,
      });
    }
  }, []);

  return (
    <Container id="card" className={className}>
      <div className="credit-card-preview w-100 w-lg-50 text-center">
        <ReactCreditCard
          {...values}
          number={values.cardNumber}
          focused={focused}
          placeholderName="Nome no cartão"
        />
      </div>
      <div className="credit-card-data mt-4">
        <InputCheckbox
          type="checkbox"
          name="my_data"
          className="mt-4 mb-3 d-block"
          options={[
            {
              label: 'Usar meus dados salvos',
              value: 'Usar meus dados salvos',
              selected: myData,
            },
          ]}
          onChange={handleChangeMyData}
        />
        <div className="mb-2">
          <span>CPF</span>
          <InputMask
            kind="cpf"
            name="document"
            className="form-control"
            value={data.document}
          />
        </div>
        <div>
          <span>Nome (igual ao do cartão)</span>
          <Input
            name="name"
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={values.name}
            className="form-control"
          />
        </div>
        <div>
          <span>Número do cartão</span>
          <InputMask
            kind="custom"
            options={{
              mask:
                cvvLength === 4 ? '9999 999999 99999' : '9999 9999 9999 9999',
            }}
            name="cardNumber"
            onChange={(e) => {
              handleChange(e);
              handleChangeCard();
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={values.cardNumber}
            className="form-control"
          />
        </div>
        <div className="d-flex mt-0">
          <div className="w-50 me-2">
            <span>Validade</span>
            <InputMask
              kind="datetime"
              options={{
                format: 'MM/YY',
              }}
              name="expiration"
              className="form-control"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.expiration}
            />
          </div>
          <div className="w-50 ms-2">
            <span>CVV</span>
            <Input
              name="cvc"
              type="number"
              onChange={(e) =>
                setValues({
                  ...values,
                  cvc: e.target.value.slice(0, cvvLength),
                })
              }
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.cvc.slice(0, cvvLength)}
              className="form-control"
            />
          </div>
        </div>

        <div className="mb-2">
          <span>E-mail</span>
          <Input
            type="email"
            name="email"
            className="form-control"
            defaultValue={data.email}
          />
        </div>
        <div className="mb-2">
          <span>Celular</span>
          <InputMask
            kind="cel-phone"
            name="phone"
            className="form-control"
            value={data.phone}
          />
        </div>
        {!user.additionalInformation.birthdate && (
          <label className="mb-2 w-100">
            <span className="fw-normal d-block mb-2">Data de nascimento</span>
            <Input
              type="date"
              name="birthdate"
              className="bd-input"
              defaultValue={data.birthdate}
            />
          </label>
        )}
        {!user.additionalInformation.gender && (
          <div className="mb-2">
            <span className="fw-normal d-block mb-2">Gênero</span>
            <InputRadio
              name="gender"
              className="d-flex justify-content-start check"
              options={[
                {
                  id: 'M',
                  value: 'Masculino',
                },
                {
                  id: 'F',
                  value: 'Feminino',
                },
              ]}
              onChange={(e) => console.log('ok')}
            />
          </div>
        )}

        <div className={`${user.additionalInformation.address && 'd-none'}`}>
          <h2 className="h5 my-3">Endereço de cobrança</h2>
          <div>
            <span>CEP</span>
            <InputMask
              kind="zip-code"
              name="zipcode"
              className="form-control"
              value={address.zipcode}
              onChange={handleChangeZipCode}
            />
          </div>
          <div>
            <span>Endereço</span>
            <Input
              name="street"
              className="form-control"
              defaultValue={address.street}
            />
          </div>
          <div>
            <span>Numero</span>
            <Input
              name="number"
              className="form-control"
              defaultValue={address.number}
            />
          </div>
          <div>
            <span>Bairro</span>
            <Input
              name="neighborhood"
              className="form-control"
              defaultValue={address.neighborhood}
            />
          </div>
          <div>
            <span>Cidade</span>
            <Input
              name="city"
              className="form-control"
              defaultValue={address.city}
            />
          </div>
          <div>
            <span>Estado</span>
            <Input
              name="state"
              className="form-control"
              defaultValue={address.state}
            />
          </div>
          <div>
            <span>Complemento</span>
            <Input
              name="complement"
              className="form-control"
              defaultValue={address.complement}
            />
          </div>
        </div>

        {!saveCard && (
          <InputCheckbox
            type="checkbox"
            name="save_card"
            className="mt-4 d-block"
            options={[
              {
                label: 'Quero salvar os dados do meu cartão',
                value: 'Quero salvar os dados do meu cartão',
                selected: check,
              },
            ]}
            onChange={handleChangeSaveCard}
          />
        )}
        {/* <Input
          name="flag"
          onChange={(e) => setValues({ ...values, flag: e.target.value })}
          value={values.flag}
          placeholder="Bandeira"
          className="form-control mt-2"
        /> */}
      </div>
    </Container>
  );
};

export default CreditCard;
