import React from 'react';
import { IProps } from './interfaces';

const KrBell: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1 25.92L25.08 20.59C24.81 19.87 24.67 19.11 24.67 18.34V14.94C24.67 11.34 22.55 8.11 19.43 6.71C19.48 6.62 19.51 6.53 19.51 6.42V3.52C19.51 1.6 17.94 0.0200005 16 0.0200005C14.06 0.0200005 12.49 1.6 12.49 3.53V6.54C12.49 6.6 12.51 6.66 12.52 6.71C9.46999 8.05 7.32999 11.1 7.32999 14.65V18.34C7.32999 19.11 7.18999 19.87 6.91999 20.59L4.89999 25.92C4.43999 27.12 5.32999 28.41 6.61999 28.41H12.49C12.49 28.41 12.49 28.44 12.49 28.46V28.63C12.49 28.63 12.49 28.71 12.5 28.75C12.65 30.55 14.16 31.97 16 31.97C17.94 31.97 19.51 30.39 19.51 28.46C19.51 28.44 19.51 28.43 19.51 28.41H25.38C26.67 28.41 27.56 27.12 27.1 25.92ZM13.69 3.54C13.69 2.26 14.73 1.23 16 1.23C17.27 1.23 18.31 2.27 18.31 3.54V6.31C17.75 6.15 17.17 6.05 16.57 6.01C16.38 6 16.18 5.99 15.99 5.99C15.19 5.99 14.41 6.11 13.68 6.31V3.54H13.69ZM18.31 28.46C18.31 29.74 17.27 30.77 16 30.77C14.73 30.77 13.69 29.73 13.69 28.46C13.69 28.44 13.69 28.43 13.69 28.41H18.34C18.34 28.41 18.34 28.44 18.34 28.46H18.31ZM25.37 26.62H6.62999L6.58999 26.56L8.60999 21.23C8.95999 20.3 9.13999 19.33 9.13999 18.34V14.65C9.13999 10.86 12.22 7.78 16.01 7.78C16.16 7.78 16.32 7.78 16.47 7.79C20.06 8.02 22.88 11.16 22.88 14.94V18.34C22.88 19.33 23.06 20.3 23.41 21.23L25.43 26.56L25.39 26.62H25.37Z"
        fill={color}
      />
    </svg>
  );
};

export default KrBell;
