import React from 'react';
import { IProps } from './interfaces';

const KrTicket: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.11 13.58C31.2 13.17 31.91 12.17 31.91 11.03V8.29C31.91 6.8 30.69 5.58 29.2 5.58H16.07C16.07 5.58 16.02 5.58 15.99 5.58C15.97 5.58 15.94 5.58 15.91 5.58H2.8C1.3 5.59 0.0900002 6.8 0.0900002 8.3V11.04C0.0900002 12.18 0.8 13.18 1.89 13.59C2.89 13.96 3.56 14.93 3.56 16.01C3.56 17.09 2.89 18.05 1.89 18.43C0.8 18.84 0.0900002 19.84 0.0900002 20.98V23.72C0.0900002 25.21 1.31 26.43 2.8 26.43H29.2C30.69 26.43 31.91 25.21 31.91 23.72V20.98C31.91 19.84 31.2 18.84 30.11 18.43C29.11 18.06 28.44 17.09 28.44 16.01C28.44 14.93 29.11 13.97 30.11 13.59V13.58ZM29.48 20.1C29.86 20.24 30.11 20.58 30.11 20.97V23.71C30.11 24.21 29.7 24.62 29.2 24.62H9.48V23.3C9.48 22.97 9.21 22.7 8.88 22.7C8.55 22.7 8.28 22.97 8.28 23.3V24.62H2.8C2.3 24.62 1.89 24.21 1.89 23.71V20.97C1.89 20.59 2.14 20.25 2.52 20.1C4.22 19.47 5.36 17.82 5.36 16C5.36 14.18 4.22 12.53 2.52 11.9C2.14 11.76 1.89 11.42 1.89 11.03V8.29C1.89 7.79 2.3 7.38 2.8 7.38H8.28V8.48C8.28 8.81 8.55 9.08 8.88 9.08C9.21 9.08 9.48 8.81 9.48 8.48V7.38H15.92C15.92 7.38 15.97 7.38 16 7.38C16.02 7.38 16.05 7.38 16.08 7.38H29.21C29.71 7.38 30.12 7.79 30.12 8.29V11.03C30.12 11.41 29.87 11.75 29.49 11.9C27.79 12.53 26.65 14.18 26.65 16C26.65 17.82 27.79 19.47 29.49 20.1H29.48Z"
        fill={color}
      />
      <path
        d="M8.88 10.85C8.55 10.85 8.28 11.12 8.28 11.45V14.41C8.28 14.74 8.55 15.01 8.88 15.01C9.21 15.01 9.48 14.74 9.48 14.41V11.45C9.48 11.12 9.21 10.85 8.88 10.85Z"
        fill={color}
      />
      <path
        d="M8.88 16.77C8.55 16.77 8.28 17.04 8.28 17.37V20.33C8.28 20.66 8.55 20.93 8.88 20.93C9.21 20.93 9.48 20.66 9.48 20.33V17.37C9.48 17.04 9.21 16.77 8.88 16.77Z"
        fill={color}
      />
    </svg>
  );
};

export default KrTicket;
