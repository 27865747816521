import React from 'react';
import { IProps } from './interfaces';

const KrInfo: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1931)">
        <path
          d="M16 0C7.16 0 0 7.16 0 16C0 24.84 7.16 32 16 32C24.84 32 32 24.84 32 16C32 7.16 24.84 0 16 0ZM16 30.2C8.17 30.2 1.8 23.83 1.8 16C1.8 8.17 8.17 1.8 16 1.8C23.83 1.8 30.2 8.17 30.2 16C30.2 23.83 23.83 30.2 16 30.2Z"
          fill={color}
        />
        <path
          d="M16 6.2C15.5 6.2 15.1 6.6 15.1 7.1V9.73C15.1 10.23 15.5 10.63 16 10.63C16.5 10.63 16.9 10.23 16.9 9.73V7.1C16.9 6.6 16.5 6.2 16 6.2Z"
          fill={color}
        />
        <path
          d="M16 13.17C15.5 13.17 15.1 13.57 15.1 14.07V24.89C15.1 25.39 15.5 25.79 16 25.79C16.5 25.79 16.9 25.39 16.9 24.89V14.07C16.9 13.57 16.5 13.17 16 13.17Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1931">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrInfo;
