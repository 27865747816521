import React from 'react';
import { IProps } from './interfaces';

const KrMapMark: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.07 5.63C12.91 5.63 10.34 8.19 10.34 11.36C10.34 14.53 12.9 17.09 16.07 17.09C19.24 17.09 21.8 14.53 21.8 11.36C21.8 8.19 19.24 5.63 16.07 5.63ZM16.07 15.88C13.57 15.88 11.54 13.85 11.54 11.35C11.54 8.85 13.57 6.82 16.07 6.82C18.57 6.82 20.6 8.85 20.6 11.35C20.6 13.85 18.57 15.88 16.07 15.88Z"
        fill={color}
      />
      <path
        d="M16.07 0.0600014C9.42 0.0600014 4 5.48 4 12.14C4 14.61 4.74 16.97 6.13 18.99L13.82 30.7C14.33 31.48 15.19 31.94 16.11 31.94C17.04 31.94 17.9 31.47 18.41 30.69L26.08 18.89C27.43 16.89 28.14 14.58 28.14 12.14C28.15 5.48 22.73 0.0600014 16.07 0.0600014ZM24.76 17.63C24.76 17.63 24.74 17.66 24.73 17.67L24.59 17.89C24.48 18.05 24.36 18.22 24.24 18.37C24.18 18.45 24.13 18.54 24.1 18.64L16.91 29.7C16.73 29.97 16.45 30.13 16.12 30.13C15.8 30.13 15.51 29.97 15.33 29.7L7.79 18.23C7.79 18.23 7.75 18.16 7.73 18.13L7.41 17.65C7.41 17.65 7.39 17.62 7.38 17.61C6.34 15.97 5.8 14.09 5.8 12.13C5.8 6.47 10.41 1.86 16.07 1.86C21.73 1.86 26.35 6.47 26.35 12.14C26.35 14.11 25.8 15.99 24.76 17.63Z"
        fill={color}
      />
    </svg>
  );
};

export default KrMapMark;
