import styled from 'styled-components';

export const Container = styled.footer`
  position: fixed;
  max-width: 576px;
  bottom: 30px;
  z-index: 5;
  a {
    padding: 16px 20px;
    text-align: center;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    :hover {
      opacity: 1;
    }
  }
  .bg {
    border-radius: 8px;
    background: #ff6900;
    box-shadow: 0px 4px 4px 0px rgba(161, 58, 0, 0.25);
  }
`;

export const PoweredBy = styled.div`
  background-color: #fff;
`;
