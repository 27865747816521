import React from 'react';
import { IProps } from './interfaces';

const KrMail: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1855)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.68 4.81H29.29C30.78 4.81 31.99 6.02 31.99 7.51V24.49C31.99 25.98 30.78 27.19 29.29 27.19H2.68C1.19 27.19 -0.0200005 25.98 -0.0200005 24.49V7.51C-0.0200005 6.02 1.19 4.81 2.68 4.81ZM16.34 16.9L29.21 6.61H2.77L15.91 16.9C16.04 17 16.21 17 16.34 16.9ZM29.29 25.39C29.79 25.39 30.19 24.99 30.19 24.49V8.12L17.46 18.3C17.07 18.62 16.59 18.78 16.12 18.78C16.0906 18.78 16.0613 18.7794 16.032 18.7781C15.5928 18.7595 15.1656 18.6012 14.8 18.32L1.78 8.13V24.49C1.78 24.99 2.18 25.39 2.68 25.39H29.29Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1855">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrMail;
