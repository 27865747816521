import React from 'react';
import { IProps } from './interfaces';

const KrClose: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5293 0.422907C30.9661 -0.140969 30.0488 -0.140969 29.4855 0.422907L15.9678 13.9559L2.46618 0.422907C1.90294 -0.140969 0.985668 -0.140969 0.422429 0.422907C-0.14081 0.986784 -0.14081 1.9051 0.422429 2.46897L13.9402 16.002L0.422429 29.5351C-0.14081 30.0989 -0.14081 31.0172 0.422429 31.5811C0.712095 31.8711 1.08222 32 1.45235 32C1.82248 32 2.19261 31.855 2.48227 31.5811L16 18.0481L29.5177 31.5811C29.8074 31.8711 30.1775 32 30.5476 32C30.9178 32 31.2879 31.855 31.5776 31.5811C32.1408 31.0172 32.1408 30.0989 31.5776 29.5351L18.0598 16.002L31.5776 2.46897C32.1408 1.9051 32.1408 0.986784 31.5776 0.422907H31.5293Z"
        fill={color}
      />
    </svg>
  );
};

export default KrClose;
