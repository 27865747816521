import styled from 'styled-components';
import banner from '~/assets/banners/banner-recuperar-senha.png';

export const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  h1 {
    color: #ff6900;
    font-size: 26px;
  }

  h2 {
    color: #ff6900;
    font-family: 'Poppins';
  }

  span {
    color: #292623;
    font-size: 12px;
    font-weight: 400;
  }

  label span {
    color: #292623;
    font-size: 12px;
    font-weight: 400;
  }

  p {
    font-family: 'Roboto';
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
  }

  .account {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
    a {
      color: #00a3e0;
    }
    button {
      color: #00a3e0;
      border: none;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .btn-back {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }

  .banner {
    background-image: url(${banner});
    height: 72px;
    width: 100%;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .btn-resend {
    color: #ff6900;
    font-family: 'Roboto';
    background-color: transparent;
    font-size: 14px;
    border: none;
    line-height: 0;
  }

  .bd-input {
    border: 1px solid #e1e1e1;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #8c8c8c;
      transition-duration: 0.2s;

      ::placeholder {
        color: #8c8c8c;
      }
    }
  }

  svg:not(.g-icon, .signin-icon) path {
    fill: #5a5a5a;
  }
`;
