import React from 'react';

import { Modal } from './styles';
import { KrClose } from '~/components/KoroIcons';

interface IProps {
  show: boolean;
  onClose(): void;
}

const ModalRegulations: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header className="d-flex align-items-center justify-content-center">
        <button type="button" onClick={onClose} className="close">
          <KrClose color="#878787" size={16} />
        </button>
        <Modal.Title className="h5 fw-bold text-center mt-4 mb-0">
          Política de Privacidade da Koro
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <p>
          A <b>Koro Esportes Ltda.</b> (doravante “Koro”) valoriza sua
          privacidade e está comprometida em proporcionar uma experiência segura
          e satisfatória ao utilizar nossa plataforma (www.koro.com.br). Em
          conformidade com a Lei Geral de Proteção de Dados (Lei 13.709/2018),
          apresentamos esta política de privacidade para explicar como
          coletamos, utilizamos e protegemos seus dados pessoais, reafirmando
          nosso compromisso com sua segurança e privacidade.
        </p>
        <h2 className="h5">1. INFORMAÇÕES PESSOAIS COLETADAS NO CADASTRO</h2>
        <p>
          Ao realizar seu cadastro na Koro, você fornecerá informações
          essenciais para sua identificação, além de dados necessários para a
          realização de compras e inscrições em eventos. As informações
          solicitadas incluem:
        </p>
        <ul>
          <li>Nome completo</li>
          <li>E-mail</li>
          <li>Documento de identificação (RG, RNE ou Passaporte)</li>
          <li>CPF</li>
          <li>Data de nascimento</li>
          <li>Gênero</li>
          <li>Cidade e estado de residência</li>
          <li>Telefone de contato</li>
        </ul>
        <p>
          Adicionalmente, ao se inscrever em um evento, você deverá preencher um
          formulário específico criado pelo organizador, contendo dados
          necessários para sua participação.
        </p>
        <ul>
          <li>
            <b>a)</b> Esse formulário é de total responsabilidade do
            organizador, que define quais informações serão coletadas.
          </li>
          <li>
            <b>b)</b> As informações serão armazenadas nos servidores da Koro
            pelo período estipulado em contrato com o organizador, podendo ser
            excluídas mediante solicitação após o término do evento.
          </li>
          <li>
            <b>c)</b> A Koro não utilizará os dados fornecidos para outros fins
            que não estejam previamente autorizados.
          </li>
          <li>
            <b>d)</b> O organizador do evento poderá acessar e utilizar os dados
            fornecidos conforme sua própria política de privacidade.
          </li>
        </ul>
        <p>
          O organizador do evento age de maneira independente em relação à Koro
          e é o único responsável pela forma como tratará as informações
          coletadas no ato da inscrição.
        </p>
        <p>
          Se você realizar a inscrição de terceiros, deverá garantir que possui
          autorização para isso e que o terceiro está ciente e de acordo com
          esta política de privacidade.
        </p>
        <h2 className="h5">2. DADOS COLETADOS AUTOMATICAMENTE</h2>
        <p>
          Durante a navegação e uso de nossos serviços, coletamos
          automaticamente algumas informações. Essas informações são obtidas por
          meio de diversas tecnologias, incluindo:
        </p>
        <ul>
          <li>
            <b>Registros de acesso (logs):</b> Coletamos dados como endereço IP,
            tipo de navegador, provedor de internet, páginas visitadas, sistema
            operacional e carimbo de data/hora, entre outros.
          </li>
          <li>
            <b>Cookies:</b> São pequenos arquivos de dados enviados ao seu
            navegador para armazenar suas preferências e facilitar a navegação.
            Você pode optar por desativar os cookies no seu navegador, embora
            isso possa afetar o desempenho de algumas funcionalidades.
          </li>
          <li>
            <b>Web Beacons:</b> Imagens eletrônicas que monitoram a navegação de
            usuários em páginas ou e-mails para análise de comportamento.
          </li>
          <li>
            <b>Scripts integrados:</b> Utilizamos scripts para coletar dados
            sobre suas interações enquanto você utiliza nossa plataforma, como
            cliques e navegação.
          </li>
          <li>
            <b>Identificação do dispositivo:</b> Usamos técnicas de impressão
            digital do dispositivo para identificá-lo com base em suas
            configurações de navegador e outros parâmetros.
          </li>
          <li>
            <b>Tecnologias de geolocalização:</b> Quando ativados, serviços de
            GPS, Wi-Fi e Bluetooth podem ser utilizados para fornecer conteúdo
            com base em sua localização.
          </li>
          <li>
            <b>Métodos de rastreamento em aplicativos:</b> Tecnologias
            específicas para monitoramento de atividades em nossos aplicativos
            móveis.
          </li>
        </ul>
        <h2 className="h5">3. FINALIDADES DO USO DAS INFORMAÇÕES</h2>
        <p>Utilizamos os dados coletados para os seguintes propósitos:</p>
        <ul>
          <li>Gerenciar e processar sua inscrição em eventos esportivos.</li>
          <li>Responder a suas solicitações e fornecer suporte ao usuário.</li>
          <li>
            Enviar avisos técnicos, alertas de segurança e informações sobre
            mudanças em nossas políticas.
          </li>
          <li>
            Melhorar e otimizar a experiência do usuário em nossa plataforma.
          </li>
          <li>
            Comunicar sobre produtos, serviços, promoções, eventos e novidades
            que possam ser de seu interesse (com sua prévia autorização).
          </li>
          <li>
            Cumprir com finalidades informadas no momento da coleta e mediante
            seu consentimento.
          </li>
          <li>
            Enviar kits e produtos relacionados aos eventos quando aplicável.
          </li>
          <li>
            Gerar boletos de pagamento e prevenir fraudes em transações
            financeiras.
          </li>
        </ul>
        <h2 className="h5">3.1 PESQUISAS E ANÁLISES</h2>
        <p>
          Com sua autorização, poderemos enviar pesquisas de opinião ou
          satisfação. Os dados coletados são analisados de forma coletiva e
          anonimizada para fins de aprimoramento de nossos serviços.
        </p>
        <h2 className="h5">3.2 ENVIO DE BOLETINS INFORMATIVOS</h2>
        <p>
          Ao realizar seu cadastro, você poderá optar por receber boletins
          informativos da Koro e de nossos parceiros. Esses boletins contêm
          informações sobre eventos, promoções e ofertas, e você poderá cancelar
          o recebimento a qualquer momento clicando no link de descadastro
          presente nos e-mails.
        </p>
        <h2 className="h5">
          3.3 COMUNICAÇÕES IMPORTANTES E NOTIFICAÇÕES DE EVENTOS
        </h2>
        <p>
          Independente de sua escolha de receber ou não boletins, enviaremos
          e-mails transacionais referentes à sua compra ou inscrição, como
          confirmações, cancelamentos e atualizações importantes sobre o evento.
          Essas notificações são fundamentais para o acompanhamento do seu
          pedido e participação no evento.
        </p>
        <h2 className="h5">4. SEGURANÇA E PROTEÇÃO DOS DADOS</h2>
        <p>
          Adotamos uma série de medidas de segurança, como a criptografia de
          dados por meio da tecnologia SSL (Secure Socket Layer), para proteger
          as informações transmitidas em nossa plataforma. Não armazenamos
          informações de pagamento em nossos servidores, e esses dados não são
          compartilhados com os organizadores dos eventos.
        </p>
        <h2 className="h5">5. DIREITOS DOS USUÁRIOS</h2>
        <p>
          Você tem o direito de solicitar a qualquer momento o acesso, correção
          ou exclusão de seus dados pessoais armazenados em nossa plataforma.
        </p>
        <p>
          Ao utilizar nossa plataforma, você concorda com os termos desta
          política de privacidade e com o tratamento de seus dados conforme aqui
          descrito.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <button type="button" onClick={onClose} className="btn close-btn">
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRegulations;
