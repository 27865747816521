import React from 'react';
import { IProps } from './interfaces';

const KrBoxes: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1952)">
        <path
          d="M12 17.09H2.65C1.18 17.09 0 18.28 0 19.75V29.1C0 30.57 1.19 31.76 2.66 31.76H12.01C13.48 31.76 14.67 30.57 14.67 29.1V19.75C14.67 18.28 13.48 17.09 12.01 17.09H12ZM12.94 29.1C12.94 29.62 12.52 30.04 12 30.04H2.65C2.13 30.04 1.71 29.62 1.71 29.1V19.75C1.71 19.23 2.13 18.81 2.65 18.81H12C12.52 18.81 12.94 19.23 12.94 19.75V29.1Z"
          fill={color}
        />
        <path
          d="M29.29 17.09H25.54L31.23 11.4C31.73 10.9 32.01 10.23 32.01 9.52C32.01 8.81 31.73 8.14 31.23 7.64L24.62 1.03C23.58 -0.0199964 21.89 -0.0199964 20.86 1.02L14.67 7.21V3.02C14.67 1.55 13.48 0.360004 12.01 0.360004H2.65C1.18 0.360004 0 1.55 0 3.02V12.37C0 13.84 1.19 15.03 2.66 15.03H12.01C13.48 15.03 14.67 13.84 14.67 12.37V11.81L19.94 17.08C18.47 17.08 17.28 18.27 17.28 19.74V29.09C17.28 30.56 18.47 31.75 19.94 31.75H29.29C30.76 31.75 31.95 30.56 31.95 29.09V19.74C31.95 18.27 30.76 17.08 29.29 17.08V17.09ZM12.94 12.37C12.94 12.89 12.52 13.31 12 13.31H2.65C2.13 13.31 1.71 12.89 1.71 12.37V3.02C1.71 2.5 2.13 2.08 2.65 2.08H12C12.52 2.08 12.94 2.5 12.94 3.02V12.37ZM15.18 9.51C15.18 9.26 15.28 9.02 15.46 8.85L22.07 2.24C22.25 2.06 22.49 1.97 22.73 1.97C22.97 1.97 23.21 2.06 23.39 2.24L30 8.86C30.18 9.04 30.28 9.27 30.28 9.52C30.28 9.77 30.18 10.01 30 10.18L23.39 16.79C23.04 17.15 22.42 17.15 22.06 16.79L15.45 10.18C15.27 10 15.17 9.77 15.17 9.52L15.18 9.51ZM30.23 29.1C30.23 29.62 29.81 30.04 29.29 30.04H19.94C19.42 30.04 19 29.62 19 29.1V19.75C19 19.23 19.42 18.81 19.94 18.81H29.29C29.81 18.81 30.23 19.23 30.23 19.75V29.1Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1952">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrBoxes;
