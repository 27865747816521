import React from 'react';
import { IProps } from './interfaces';

const KrPix: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1977)">
        <path
          d="M15.17 18.33C15.51 17.99 16.09 17.99 16.43 18.33L21.25 23.15C22.14 24.04 23.32 24.53 24.58 24.53H25.53L19.45 30.61C17.55 32.46 14.47 32.46 12.57 30.61L6.46 24.51H7.04C8.29 24.51 9.48 24.02 10.37 23.13L15.18 18.32L15.17 18.33ZM16.43 13.72C16.03 14.06 15.52 14.07 15.17 13.72L10.36 8.91C9.47 7.96 8.29 7.53 7.03 7.53H6.45L12.56 1.43C14.46 -0.469995 17.55 -0.469995 19.45 1.43L25.54 7.52H24.59C23.34 7.52 22.15 8.01 21.26 8.9L16.44 13.72H16.43ZM7.04 8.94C7.9 8.94 8.7 9.29 9.36 9.9L14.17 14.71C14.62 15.1 15.21 15.39 15.81 15.39C16.41 15.39 16.99 15.11 17.44 14.71L22.26 9.89001C22.87 9.28001 23.72 8.93001 24.58 8.93001H26.94L30.59 12.58C32.49 14.48 32.49 17.56 30.59 19.46L26.94 23.11H24.58C23.72 23.11 22.87 22.76 22.26 22.15L17.44 17.33C16.57 16.46 15.05 16.46 14.18 17.33L9.37 22.13C8.71 22.74 7.91 23.09 7.05 23.09H5.06L1.42 19.46C-0.479997 17.56 -0.479997 14.48 1.42 12.58L5.06 8.94H7.05H7.04Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1977">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrPix;
