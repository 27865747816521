import React from 'react';
import { IProps } from './interfaces';

const KrUser: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1848)">
        <path
          d="M31.9 28.8C31.16 23.39 27.76 18.81 22.9 16.5C24.63 14.75 25.71 12.35 25.71 9.7C25.71 4.34 21.37 0 16.01 0C10.65 0 6.3 4.34 6.3 9.7C6.3 12.35 7.38 14.75 9.11 16.5C4.24 18.81 0.839998 23.39 0.0999983 28.8C-0.0100017 29.59 0.229998 30.38 0.749998 30.98C1.25 31.56 1.99 31.89 2.75 31.89H29.24C30.01 31.89 30.74 31.56 31.24 30.98C31.76 30.39 31.99 29.59 31.89 28.8H31.9ZM16 2.01C20.24 2.01 23.69 5.46 23.69 9.7C23.69 12.15 22.51 14.47 20.53 15.92C19.21 16.88 17.64 17.39 16 17.39C14.36 17.39 12.79 16.88 11.47 15.92C9.49 14.47 8.31 12.15 8.31 9.7C8.31 5.46 11.76 2.01 16 2.01ZM29.89 29.79C29.72 29.98 29.49 30.08 29.24 30.08H2.75C2.5 30.08 2.27 29.98 2.1 29.79C1.92 29.59 1.84 29.31 1.88 29.04C2.57 23.96 5.91 19.7 10.65 17.79C12.18 18.81 14.02 19.4 16 19.4C17.98 19.4 19.81 18.8 21.35 17.79C26.08 19.7 29.42 23.96 30.12 29.04C30.16 29.32 30.08 29.59 29.9 29.79H29.89Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1848">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrUser;
