/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';

import { IoFilter, IoClose } from 'react-icons/io5';
import { Form } from '@unform/web';
import { addMonths, format } from 'date-fns';
import { SearchInput, Modal, Container } from './styles';
import { KrFilter, KrSearch } from '../KoroIcons';
import InputDate from '../InputDate';
import Select, { IOption } from '../Select';
import api from '~/services/api';

export interface IFilter {
  startDate: Date;
  endDate: Date;
  locale: string;
  category: {
    id?: number;
    name: string;
  };
  organizer: {
    id?: number;
    name: string;
  };
}

interface ISearch {
  className?: string;
  filter?: boolean;
  filterBy?: boolean;
  category?: string;
  search?: string;
  startDate?: Date;
  endDate?: Date;
  onChangeFilter?(filter: IFilter): void;
  onSearch?(value: string): void;
}

interface IAddress {
  id: number;
  city: string;
  state: string;
}

interface ICategory {
  id: number;
  name: string;
}

interface IOrganizer {
  id: number;
  name: string;
}

interface IFormData {
  startDate: Date;
  endDate: Date;
  locale: string;
  category: string;
  organizer: string;
}

const InputSearch: React.FC<ISearch> = ({
  className,
  filter,
  filterBy,
  category,
  search,
  startDate: dateStart,
  endDate: dateEnd,
  onChangeFilter,
  onSearch,
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addMonths(new Date(), 3));
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState<IFilter>({
    startDate,
    endDate,
    locale: 'Brasil',
    category: {
      name: 'Todas',
    },
    organizer: {
      name: 'Todos',
    },
  });
  const [addresses, setAddresses] = useState<IOption[]>([]);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [organizers, setOrganizers] = useState<IOption[]>([]);

  useEffect(() => {
    if (dateStart) {
      setStartDate(dateStart);
    }

    if (dateEnd) {
      setEndDate(dateEnd);
    }
  }, [dateEnd, dateStart]);

  const handleLoadAddresses = useCallback(async () => {
    const response = await api.get<IAddress[]>('addresses/events');
    const data = response.data.map<IOption>((addressData) => ({
      id: addressData.id,
      value: `${addressData.city}/${addressData.state}`,
      selected: false,
    }));
    setAddresses([
      {
        id: 0,
        value: 'Brasil',
        selected: true,
      },
      ...data,
    ]);
  }, []);

  const handleLoadCategories = useCallback(async () => {
    const response = await api.get<ICategory[]>('events-types');
    const data = response.data.map<IOption>((categoryData) => ({
      id: categoryData.id,
      value: categoryData.name,
      selected: category?.toLowerCase() === categoryData.name.toLowerCase(),
    }));
    const categorySelected = data.find((categoryData) => categoryData.selected);
    if (categorySelected) {
      setFilters((oldState) => ({
        ...oldState,
        category: {
          id: categorySelected.id as number,
          name: categorySelected.value,
        },
      }));
    }
    setCategories([
      {
        id: 0,
        value: 'Todas',
        selected: !category,
      },
      ...data,
    ]);
  }, [category]);

  const handleLoadOrganizers = useCallback(async () => {
    const response = await api.get<IOrganizer[]>('organizers');
    const data = response.data.map<IOption>((organizerData) => ({
      id: organizerData.id,
      value: organizerData.name,
      selected: false,
    }));
    setOrganizers([
      {
        id: 0,
        value: 'Todos',
        selected: true,
      },
      ...data,
    ]);
  }, []);

  useEffect(() => {
    handleLoadAddresses();
    handleLoadCategories();
    handleLoadOrganizers();
  }, [handleLoadAddresses, handleLoadCategories, handleLoadOrganizers]);

  useEffect(() => {
    if (onChangeFilter) {
      onChangeFilter(filters);
    }
  }, [filters, onChangeFilter]);

  useEffect(() => {
    if (onSearch && search) {
      onSearch(search);
    }
  }, [onSearch, search]);

  const handleStartDate = useCallback((e) => {
    setStartDate(e);
  }, []);

  const handleEndDate = useCallback((e) => {
    setEndDate(e);
  }, []);

  const handleSubmit = useCallback(
    (data: IFormData) => {
      const address = addresses.find(
        (addressData) => addressData.id.toString() === data.locale
      );
      const categorySelected = categories.find(
        (categoryData) => categoryData.id.toString() === data.category
      );
      const organizer = organizers.find(
        (organizerData) => organizerData.id.toString() === data.organizer
      );

      setFilters({
        startDate,
        endDate,
        locale: address?.value || 'Brasil',
        category: {
          id: categorySelected?.id as number,
          name: categorySelected?.value || 'Todas',
        },
        organizer: {
          id: organizer?.id as number,
          name: organizer?.value || 'Todos',
        },
      });

      setShow(false);
    },
    [addresses, categories, endDate, organizers, startDate]
  );

  const handleChangeAddresses = useCallback(
    (e) => {
      const newAddresses = addresses.map((addressData) => ({
        ...addressData,
        selected: addressData.id === e.id,
      }));

      setAddresses(newAddresses);
    },
    [addresses]
  );

  const handleChangeCategories = useCallback(
    (e) => {
      const newCategories = categories.map((categoryData) => ({
        ...categoryData,
        selected: categoryData.id === e.id,
      }));

      setCategories(newCategories);
    },
    [categories]
  );

  const handleChangeOrganizers = useCallback(
    (e) => {
      const newOrganizers = organizers.map((organizerData) => ({
        ...organizerData,
        selected: organizerData.id === e.id,
      }));

      setOrganizers(newOrganizers);
    },
    [organizers]
  );

  return (
    <Container className="w-100">
      <div className="d-flex">
        <SearchInput className={className}>
          <label htmlFor="search">
            <KrSearch size={14} color="#5a5a5a" className="me-1" />
          </label>
          <input
            id="search"
            type="text"
            placeholder="Buscando por algo?"
            className="border-0"
            onChange={(e) => onSearch && onSearch(e.target.value)}
            defaultValue={search}
          />
        </SearchInput>
        {filter && (
          <button
            type="button"
            onClick={() => setShow(true)}
            className="border-0 bg-transparent btn-filter ms-3"
          >
            <KrFilter color="#878787" size={24} />
          </button>
        )}
      </div>

      {filterBy && (
        <p className="pt-3 mb-0">
          <span>Filtrando por:</span> {filters.category.name},{' '}
          {filters.locale ? `${filters.locale},` : ''} entre{' '}
          {format(filters.startDate, 'dd/MM')} e{' '}
          {format(filters.endDate, 'dd/MM')};
        </p>
      )}

      <Modal
        className="modal-filter"
        size="xl"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
            <Modal.Title className="mb-3 mt-4">
              <h2 className="title h5 fw-semibold mb-0">Filtros</h2>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="border-0"
            >
              <IoClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="pt-0 px-3 px-sm-5">
            <p className="cache-subtitle mb-4">
              <span>Filtrando por:</span> Corrida, São Paulo - SP, entre 12/10 e
              01/12;
            </p>
            <h4 className="fw-normal h6 mb-1">Data</h4>
            <span className="d-block">de</span>
            <InputDate
              name="dateStart"
              selected={startDate}
              onChange={handleStartDate}
            />
            <span className="d-block mt-2">até</span>
            <InputDate
              name="dateStart"
              selected={endDate}
              onChange={handleEndDate}
            />
            <h4 className="fw-normal h6 mb-1 mt-3">Local</h4>
            <Select
              name="locale"
              options={addresses}
              onChange={handleChangeAddresses}
            />
            <h4 className="fw-normal h6 mb-1 mt-3">Categoria</h4>
            <Select
              name="category"
              options={categories}
              onChange={handleChangeCategories}
            />
            <h4 className="fw-normal h6 mb-1 mt-3">Organizador</h4>
            <Select
              name="organizer"
              options={organizers}
              onChange={handleChangeOrganizers}
            />
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5">
            <button
              type="submit"
              className="w-100 mx-0 btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 mt-2 mb-0"
            >
              Filtrar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default InputSearch;
