/* eslint-disable no-restricted-syntax */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ButtonBack from '~/components/ButtonBack';
import { Container } from './styles';
import Input from '~/components/Input';
import KrBoxes from '~/components/KoroIcons/KrBoxes';
import Signin from '~/components/Signin';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { useAuth } from '~/hooks/Auth';
import { IItem, useCart } from '~/hooks/Cart';

interface IBatchCategory {
  id: number;
  price: string;
}

interface IModalityEvent {
  id: number;
  modality: {
    id: number;
    name: string;
  };
}

interface ICategory {
  id: number;
  name: string;
  batchesCategories: IBatchCategory[];
}

interface ICategoryModality {
  id: number;
  modality_event_id: number;
  categories: ICategory[];
  modalityEvent: IModalityEvent;
}

interface IResponseCategoryModality {
  id: number;
  modality_event_id: number;
  category: ICategory;
  modalityEvent: IModalityEvent;
}

interface IParams {
  slug: string;
}

interface IQtyTicket {
  [key: string]: IItem;
}

const CheckoutCategories: React.FC = () => {
  const { user } = useAuth();
  const { handleChangeItems } = useCart();
  const history = useHistory();
  const params = useParams<IParams>();
  const [qtyTickets, setQtyTickets] = useState({} as IQtyTicket);
  const [login, setLogin] = useState(false);
  const [eventId, setEventId] = useState<number | undefined>(undefined);
  const [categoriesModalities, setCategoriesModalities] = useState<
    ICategoryModality[]
  >([]);

  useEffect(() => {
    api
      .get(`events/${params.slug}`, {
        params: {
          withoutRelations: true,
        },
      })
      .then((response) => {
        setEventId(response.data.id);
      });
  }, [params.slug]);

  const loadCategoriesModalities = useCallback(async (event_id) => {
    const response = await api.get<IResponseCategoryModality[]>(
      `categories-modalities/events/${event_id}`
    );

    const data = response.data.reduce((previous, current) => {
      const newData = previous.slice();
      const itemIndex = newData.findIndex(
        (categoryModality) =>
          categoryModality.modality_event_id === current.modality_event_id
      );

      if (itemIndex >= 0) {
        newData[itemIndex].categories.push(current.category);
      } else {
        newData.push({
          ...current,
          categories: [current.category],
        });
      }

      return newData;
    }, [] as ICategoryModality[]);

    setCategoriesModalities(data);
  }, []);

  useEffect(() => {
    if (eventId) {
      loadCategoriesModalities(eventId);
    }
  }, [eventId, loadCategoriesModalities]);

  const handleQtdKit = useCallback(
    (
      categoryId: string,
      batch_category_id: number,
      modality_id: number,
      modality: string,
      category: string,
      quantity: number,
      value: number
    ) => {
      setQtyTickets((prev) => ({
        ...prev,
        [categoryId]: {
          batch_category_id,
          modality_id,
          modality,
          category,
          quantity,
          value,
        },
      }));
    },
    []
  );

  const handlePlus = useCallback(
    (
      categoryId: string,
      batch_category_id: number,
      modality_id: number,
      modality: string,
      category: string,
      quantity: number,
      value: number
    ) => {
      const data = {
        [categoryId]: {
          batch_category_id,
          modality_id,
          modality,
          category,
          quantity: quantity + 1,
          value,
        },
      };
      setQtyTickets((prev) => ({
        ...prev,
        ...data,
      }));
    },
    []
  );

  const handleMinus = useCallback(
    (
      categoryId: string,
      batch_category_id: number,
      modality_id: number,
      modality: string,
      category: string,
      quantity: number,
      value: number
    ) => {
      const data = {
        [categoryId]: {
          batch_category_id,
          modality_id,
          modality,
          category,
          quantity: quantity > 0 ? quantity - 1 : 0,
          value,
        },
      };
      setQtyTickets((prev) => ({
        ...prev,
        ...data,
      }));
    },
    []
  );

  const handleSubmit = useCallback(() => {
    try {
      if (user) {
        const items: IItem[] = [];

        for (const item of Object.values(qtyTickets)) {
          for (let i = 0; i < item.quantity; i += 1) {
            items.push(item);
          }
        }

        if (items.length === 0) {
          throw new Error('no items');
        }

        handleChangeItems(items);
        history.push(`${process.env.PUBLIC_URL}/eventos/${params.slug}/resumo`);
      } else {
        setLogin(true);
      }
    } catch (error) {
      Swal.fire({
        text: 'Nenhuma categoria seleciona, escolha ao menos uma categoria',
        icon: 'warning',
        iconColor: '#ff6900',
        confirmButtonColor: '#ff6900',
      });
    }
  }, [handleChangeItems, history, params.slug, qtyTickets, user]);

  const subtotal = useMemo(() => {
    if (Object.keys(qtyTickets).length > 0) {
      localStorage.setItem('@Koro:qtyTickets', JSON.stringify(qtyTickets));
    }
    const data = Object.keys(qtyTickets).reduce((previous, current) => {
      return (
        previous + qtyTickets[current].value * qtyTickets[current].quantity
      );
    }, 0);

    return data;
  }, [qtyTickets]);

  useEffect(() => {
    const localQtyTickets = localStorage.getItem('@Koro:qtyTickets');
    if (localQtyTickets) {
      setQtyTickets(JSON.parse(localQtyTickets));
    }
  }, []);

  return (
    <Container>
      {login ? (
        <Signin signin={login} onClick={() => setLogin(false)} />
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 text-center pt-3">
              <ButtonBack className="mt-3" />
              <div className="banner my-3" />
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h2 className="h5 mb-0 d-flex align-items-center">
                  <KrBoxes color="#292623" size={20} className="me-2" />
                  Categorias
                </h2>
              </div>
            </div>

            <div className="col-11 px-3">
              <p>
                Escolha a(s) categoria para a(s) modalidade(s) selecionadas(s)
              </p>
              <Form onSubmit={handleSubmit}>
                {categoriesModalities.map((categoryModality) => (
                  <div key={categoryModality.id}>
                    <div className="bg-category p-3 mt-3">
                      <h3 className="h6 fw-semibold mb-1">
                        {categoryModality.modalityEvent.modality.name}
                      </h3>
                    </div>
                    {categoryModality.categories.map((category) => (
                      <div
                        key={category.id}
                        className="bd-mod d-flex align-items-center p-2 mt-2"
                      >
                        <span>
                          <span className="text-black">{category.name}</span> -{' '}
                          {formatPrice(
                            parseFloat(category.batchesCategories[0].price)
                          )}
                        </span>
                        <button
                          type="button"
                          onClick={() =>
                            handleMinus(
                              `${categoryModality.modalityEvent.id}-${category.id}`,
                              category.batchesCategories[0].id,
                              categoryModality.modalityEvent.modality.id,
                              categoryModality.modalityEvent.modality.name,
                              category.name,
                              (Object.keys(qtyTickets).length > 0 &&
                                qtyTickets[
                                  `${categoryModality.modalityEvent.id}-${category.id}`
                                ].quantity) ||
                                0,
                              parseFloat(category.batchesCategories[0].price)
                            )
                          }
                          className="ms-auto pe-2 border-0 bg-transparent"
                        >
                          <HiOutlineMinus
                            size={15}
                            color={
                              Object.keys(qtyTickets).length > 0 &&
                              qtyTickets[
                                `${categoryModality.modalityEvent.id}-${categoryModality.id}`
                              ] &&
                              qtyTickets[
                                `${categoryModality.modalityEvent.id}-${categoryModality.id}`
                              ].quantity !== 0
                                ? '#292623'
                                : '#E1E1E1'
                            }
                          />
                        </button>
                        <Input
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleQtdKit(
                              `${categoryModality.modalityEvent.id}-${category.id}`,
                              category.batchesCategories[0].id,
                              categoryModality.modalityEvent.modality.id,
                              categoryModality.modalityEvent.modality.name,
                              category.name,
                              parseInt(e.target.value, 10),
                              parseFloat(category.batchesCategories[0].price)
                            )
                          }
                          value={
                            (Object.keys(qtyTickets).length > 0 &&
                              qtyTickets[
                                `${categoryModality.modalityEvent.id}-${category.id}`
                              ]?.quantity) ||
                            0
                          }
                          type="number"
                          name={`${categoryModality.modalityEvent.id}-${category.id}`}
                          className="qtd-kits"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            handlePlus(
                              `${categoryModality.modalityEvent.id}-${category.id}`,
                              category.batchesCategories[0].id,
                              categoryModality.modalityEvent.modality.id,
                              categoryModality.modalityEvent.modality.name,
                              category.name,
                              (Object.keys(qtyTickets).length > 0 &&
                                qtyTickets[
                                  `${categoryModality.modalityEvent.id}-${category.id}`
                                ]?.quantity) ||
                                0,
                              parseFloat(category.batchesCategories[0].price)
                            )
                          }
                          className="ps-2 border-0 bg-transparent"
                        >
                          <HiOutlinePlus size={15} />
                        </button>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="d-flex justify-content-between mt-3">
                  <p className="fs-5">Subtotal:</p>
                  <p className="h5 fw-medium">{formatPrice(subtotal)}</p>
                </div>
                <button
                  type="submit"
                  className="w-100 btn btn-login h6 fw-normal py-2 mt-3"
                >
                  Continuar
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CheckoutCategories;
