import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { RiPencilFill } from 'react-icons/ri';
import { FaRegTrashCan } from 'react-icons/fa6';
import { GoPlus } from 'react-icons/go';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { CardList, ChangePassword, Container } from './styles';
import Input from '~/components/Input';
import { KrCreditCard, KrTrash } from '~/components/KoroIcons';

import mastercard from '~/assets/logos/mastercard.svg';
import visa from '~/assets/logos/visa.svg';
import americanExpress from '~/assets/logos/american-express.svg';
import elo from '~/assets/logos/elo.svg';

import Toast from '~/utils/toast';

interface IProps {
  reloadCards: number;
  handleShowAddCard(): void;
}

interface IFormData {
  current_password: string;
  password: string;
  confirm_password: string;
}

interface ICard {
  id: number;
  name: string;
  number: string;
  due_date: string;
  flag: string;
}

interface ICardResponse {
  data: ICard[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

const Security: React.FC<IProps> = ({ handleShowAddCard, reloadCards }) => {
  const formRef = useRef<FormHandles>(null);
  const [changePassword, setChangePassword] = useState(false);
  const [cardActive, setCardActive] = useState(false);
  const [cards, setCards] = useState<ICard[]>([]);

  const loadCards = useCallback(async (pageSelected) => {
    const response = await api.get<ICardResponse>(`cards/users`, {
      params: {
        page: pageSelected,
      },
    });
    const data = response.data.data.map<ICard>((card) => {
      let flag = '';
      switch (card.flag.toLowerCase()) {
        case 'mastercard':
          flag = mastercard;
          break;
        case 'visa':
          flag = visa;
          break;
        case 'amex':
          flag = americanExpress;
          break;
        case 'elo':
          flag = elo;
          break;

        default:
          flag = '';
          break;
      }

      return {
        ...card,
        flag,
        number: `**** **** **** ${card.number}`,
      };
    });

    if (pageSelected === 1) {
      setCards(data);
    } else {
      setCards((state) => [...state, ...data]);
    }
  }, []);

  useEffect(() => {
    loadCards(1);
  }, [loadCards, reloadCards]);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        current_password: Yup.string().required('A senha atual é obrigatório'),
        password: Yup.string().required('A nova senha é obrigatório'),
        confirm_password: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas devem ser iguais'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { current_password, password } = data;

      const formData = {
        current_password,
        password,
      };
      await api.put(`users/sessions/password`, formData);

      Toast.fire({
        icon: 'success',
        iconColor: '#ff6900',
        title: 'Senha alterada com sucesso!',
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
      }
    }
  }, []);

  const handleDeleteCard = useCallback((cardId) => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#ff6900',
      title: 'Gostaria de excluir o cartão?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#ff6900',
      cancelButtonColor: '#B34A00',
      reverseButtons: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        setCards((allCards) => allCards.filter((card) => card.id !== cardId));
        await api.delete(`cards/${cardId}`);
        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Cartão excluído com sucesso!',
        });
      }
    });
  }, []);

  return (
    <Container className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg p-3 mt-3">
            <button
              type="button"
              onClick={() => setChangePassword(!changePassword)}
              className="btn-title d-flex justify-content-between align-items-center w-100 mb-3"
            >
              Alterar Senha
              <IoIosArrowDown
                size={14}
                color="#808080"
                className={`${changePassword ? 'rotate-arrow' : 'right-arrow'}`}
              />
            </button>
            <ChangePassword className="border-bottom" active={changePassword}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="d-flex flex-column"
              >
                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">Senha atual</span>
                  <Input
                    type="password"
                    name="current_password"
                    className="bd-input"
                  />
                </label>

                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">Nova senha</span>
                  <Input type="password" name="password" className="bd-input" />
                </label>

                <label className="">
                  <span className="fw-normal d-block mb-2">
                    Confirmar a nova senha
                  </span>
                  <Input
                    type="password"
                    name="confirm_password"
                    className="bd-input"
                  />
                </label>

                <button
                  type="submit"
                  className="btn btn-change mt-4 mb-3 h6 fw-normal py-2"
                >
                  Alterar
                </button>
              </Form>
            </ChangePassword>
            <button
              type="button"
              onClick={() => setCardActive(!cardActive)}
              className="btn-title d-flex justify-content-between align-items-center w-100 mt-3"
            >
              Cartões Salvos
              <IoIosArrowDown
                size={14}
                color="#808080"
                className={`${cardActive ? 'rotate-arrow' : 'right-arrow'}`}
              />
            </button>
            <CardList active={cardActive} cardsQtd={cards.length}>
              {cards.map((card, index) => (
                <div
                  key={card.id}
                  className="border-bottom d-flex align-items-center p-2 mt-3"
                >
                  <div className="me-3">
                    {card.flag ? (
                      <img src={card.flag} alt="Logo cartão" />
                    ) : (
                      <div className="card-default">
                        <KrCreditCard
                          size={20}
                          color="#fff"
                          className="svg-card"
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <h4 className="mb-0">{card.name}</h4>
                    <p className="mb-0">{card.number}</p>
                  </div>
                  <div className="d-flex ms-auto">
                    <button
                      type="button"
                      onClick={() => handleDeleteCard(card.id)}
                      className="border-0 ps-3 ms-1"
                    >
                      <KrTrash size={14} color="#B41717" className="svg-card" />
                    </button>
                  </div>
                </div>
              ))}

              <div className="text-center mt-3">
                <button
                  type="button"
                  onClick={handleShowAddCard}
                  className="btn-payment border-0"
                >
                  <GoPlus size={16} color="#000" className="me-2" />
                  Adicionar novo método de pagamento
                </button>
              </div>
            </CardList>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Security;
