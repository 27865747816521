import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  li {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 20px;

    width: 100%;
  }

  button {
    color: #ff6900;
  }

  .close {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
