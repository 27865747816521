import styled from 'styled-components';

interface IChangePassword {
  active: boolean;
}

interface ICardList {
  active: boolean;
  cardsQtd: number;
}

export const Container = styled.div`
  label span {
    color: #292623;
    font-size: 12px;
  }
  .bg {
    border-radius: 8px;
    background: #fff;
  }

  .btn-title {
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    border: none;
  }

  .btn-change {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .bd-input {
    border: 1px solid #e1e1e1;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #8c8c8c;
      transition-duration: 0.2s;

      ::placeholder {
        color: #8c8c8c;
      }
    }
  }

  svg:not(.svg-card) path {
    fill: #5a5a5a;
  }

  .rotate-arrow {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
  }
  .right-arrow {
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
  }
`;

export const ChangePassword = styled.div<IChangePassword>`
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.active ? '300px' : '1px')};
  overflow: ${(props) => (props.active ? 'auto' : 'hidden')};

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

export const CardList = styled.div<ICardList>`
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.active ? props.cardsQtd * 66 + 44 : '1')}px;
  overflow: ${(props) => (props.active ? 'auto' : 'hidden')};

  .card-default {
    width: 32px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff6900bf;
    border-radius: 2px;
  }

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }

  img {
    max-width: 32px;
  }

  h4 {
    color: #000;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }
  p {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }

  .btn-payment {
    color: #000;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }
`;
