import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import ButtonBack from '~/components/ButtonBack';
import Input from '~/components/Input';

interface IFormData {
  email: string;
}

const RecoverPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api.post('users/sessions/forgot-password', {
        email: data.email,
      });

      Swal.fire(
        'Tudo certo!',
        'Um e-mail foi enviado para você recuperar sua conta',
        'success'
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente', 'error');
      }
    }
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 text-center pt-3">
            <ButtonBack className="mt-3" />
            <div className="banner my-3" />
            <h1 className="text-start mb-3">Recuperando senha</h1>
            <p>
              Para alterar a senha, favor preencher com o e-mail cadastrado para
              que possamos enviar um link para geração de nova senha
            </p>
          </div>

          <div className="col-11 px-3">
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column pb-3"
            >
              <label className="my-4">
                <Input name="email" type="email" className="bd-input" />
              </label>

              <button
                type="submit"
                className="btn btn-login h6 fw-normal py-2 mt-4"
              >
                Continuar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RecoverPassword;
