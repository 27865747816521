/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';

import { useHistory, useParams } from 'react-router-dom';
import { Container } from './styles';
import Input from '~/components/Input';
import { KrInfo, KrUserDocument } from '~/components/KoroIcons';
import ButtonBack from '~/components/ButtonBack';
import InputCheckbox from '~/components/InputCheckbox';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import { useAuth } from '~/hooks/Auth';
import { IItem, useCart } from '~/hooks/Cart';
import api from '~/services/api';

interface ISize {
  id: number;
  size: string;
}

interface IProduct {
  sizes: ISize[];
}

interface IKit {
  id: number;
  products: IProduct[];
}

interface IEvent {
  id: number;
  kits: IKit[];
}

interface ITicket {
  myTicket?: boolean;
  index: number;
  item: IItem;
  document?: string;
  name?: string;
  phone?: string;
  email?: string;
  error?: {
    document?: string;
    name?: string;
    phone?: string;
    email?: string;
  };
}

interface IParams {
  slug: string;
}

const CheckoutOwnership: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { cart, handleChangeItems } = useCart();
  const history = useHistory();
  const params = useParams<IParams>();
  const [indexSel, setIndexSel] = useState<number>(-1);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [sizes, setSizes] = useState<IOption[]>([]);
  const [eventId, setEventId] = useState<number | undefined>();

  useEffect(() => {
    api.get<IEvent>(`events/${params.slug}`).then((response) => {
      if (response.data.kits) {
        const sizesData: IOption[] = [];
        for (const kit of response.data.kits) {
          const product = kit.products.find(
            (productData) => productData.sizes && productData.sizes.length > 0
          );
          if (product) {
            const sizesAux = product.sizes.map<IOption>((size) => ({
              id: size.id,
              value: size.size,
              selected: false,
            }));
            sizesData.push(...sizesAux);
          }
        }
        setSizes(sizesData);
      }

      setEventId(response.data.id);
    });
  }, [params.slug]);

  useEffect(() => {
    const data = cart.items.map<ITicket>((item, index) => ({
      index,
      item,
    }));

    setTickets(data);
  }, [cart, cart.items]);

  const handleChangeMyTicket = useCallback(
    (item, index) => {
      const newTickets = tickets.slice();
      const ticketIndex = newTickets.findIndex(
        (ticket) => ticket.index === index
      );

      if (ticketIndex >= 0) {
        newTickets[ticketIndex].myTicket = !newTickets[ticketIndex].myTicket;
        newTickets[ticketIndex].error = {};

        if (newTickets[ticketIndex].myTicket) {
          newTickets[ticketIndex].document =
            user.additionalInformation.document;
          newTickets[ticketIndex].name = user.name;
          newTickets[ticketIndex].phone = user.additionalInformation.phone;
          newTickets[ticketIndex].email = user.email;
          setIndexSel(index);
        } else {
          newTickets[ticketIndex].document = undefined;
          newTickets[ticketIndex].name = undefined;
          newTickets[ticketIndex].phone = undefined;
          newTickets[ticketIndex].email = undefined;
          setIndexSel(-1);
        }
      } else {
        newTickets.push({
          myTicket: true,
          index,
          item,
          document: user.additionalInformation.document,
          name: user.name,
          phone: user.additionalInformation.phone,
          email: user.email,
        });
        setIndexSel(index);
      }
      setTickets(newTickets);
    },
    [tickets, user]
  );

  const handleChangeDocument = useCallback(
    async (e, index, item) => {
      const newTickets = tickets.slice();
      const ticketIndex = newTickets.findIndex(
        (ticket) => ticket.index === index
      );
      const { value } = e.target;

      const data = {
        name: '',
        phone: '',
        email: '',
      };
      if (value.length === 14) {
        const response = await api.get(`tickets/document/${value}`);
        if (response.data.length > 0) {
          data.name = response.data[0].name;
          data.phone = response.data[0].phone;
          data.email = response.data[0].email;
        }
      }

      if (ticketIndex >= 0) {
        newTickets[ticketIndex].document = value;
        newTickets[ticketIndex].name = data.name;
        newTickets[ticketIndex].phone = data.phone;
        newTickets[ticketIndex].email = data.email;
      } else {
        newTickets.push({
          index,
          item,
          document: value,
          name: data.name,
          phone: data.phone,
          email: data.email,
        });
      }

      const items: IItem[] = [];
      for (const ticket of newTickets) {
        items.push({
          ...ticket.item,
          ticket: {
            id: new Date().getTime(),
            document: ticket.document,
            name: ticket.name,
            email: ticket.email,
            phone: ticket.phone,
          },
        });
      }

      handleChangeItems(items);
      setTickets(newTickets);
    },
    [handleChangeItems, tickets]
  );

  const handleChangeName = useCallback(
    (e, index, item) => {
      const newTickets = tickets.slice();
      const ticketIndex = newTickets.findIndex(
        (ticket) => ticket.index === index
      );
      const { value } = e.target;
      if (ticketIndex >= 0) {
        newTickets[ticketIndex].name = value;
      } else {
        newTickets.push({
          index,
          item,
          name: value,
        });
      }

      const items: IItem[] = [];
      let i = 0;
      for (const ticket of newTickets) {
        items.push({
          ...ticket.item,
          ticket: {
            id: new Date().getTime(),
            document: ticket.item.ticket?.document,
            name: i === index ? value : ticket.item.ticket?.name,
            email: ticket.item.ticket?.email,
            phone: ticket.item.ticket?.phone,
          },
        });
        i += 1;
      }

      handleChangeItems(items);
      setTickets(newTickets);
    },
    [handleChangeItems, tickets]
  );

  const handleChangePhone = useCallback(
    (e, index, item) => {
      const newTickets = tickets.slice();
      const ticketIndex = newTickets.findIndex(
        (ticket) => ticket.index === index
      );
      const { value } = e.target;
      if (ticketIndex >= 0) {
        newTickets[ticketIndex].phone = value;
      } else {
        newTickets.push({
          index,
          item,
          phone: value,
        });
      }

      const items: IItem[] = [];
      let i = 0;
      for (const ticket of newTickets) {
        items.push({
          ...ticket.item,
          ticket: {
            id: new Date().getTime(),
            document: ticket.item.ticket?.document,
            name: ticket.item.ticket?.name,
            email: ticket.item.ticket?.email,
            phone: i === index ? value : ticket.item.ticket?.phone,
          },
        });
        i += 1;
      }

      handleChangeItems(items);
      setTickets(newTickets);
    },
    [handleChangeItems, tickets]
  );

  const handleChangeEmail = useCallback(
    (e, index, item) => {
      const newTickets = tickets.slice();
      const ticketIndex = newTickets.findIndex(
        (ticket) => ticket.index === index
      );
      const { value } = e.target;
      if (ticketIndex >= 0) {
        newTickets[ticketIndex].email = value;
      } else {
        newTickets.push({
          index,
          item,
          email: value,
        });
      }

      const items: IItem[] = [];
      let i = 0;
      for (const ticket of newTickets) {
        items.push({
          ...ticket.item,
          ticket: {
            id: new Date().getTime(),
            document: ticket.item.ticket?.document,
            name: ticket.item.ticket?.name,
            email: i === index ? value : ticket.item.ticket?.email,
            phone: ticket.item.ticket?.phone,
          },
        });
        i += 1;
      }

      handleChangeItems(items);
      setTickets(newTickets);
    },
    [handleChangeItems, tickets]
  );

  const findSameDocument = useCallback(
    (document, index) => {
      const duplicate = tickets.find(
        (ticket, idx) =>
          (ticket.item.ticket?.document === document ||
            ticket.document === document) &&
          idx !== index
      );
      return duplicate;
    },
    [tickets]
  );

  const handleSubmit = useCallback(async () => {
    const newTickets = tickets.map((ticket) => {
      return {
        ...ticket,
        error: {},
      };
    });
    try {
      const hasDocumentError = !!newTickets.find(
        (ticket) =>
          !ticket.document &&
          (ticket.name || ticket.phone || ticket.email) &&
          !ticket.myTicket
      );
      const hasNameError = !!newTickets.find(
        (ticket) =>
          !ticket.name &&
          (ticket.document || ticket.phone || ticket.email) &&
          !ticket.myTicket
      );
      const hasPhoneError = !!newTickets.find(
        (ticket) =>
          !ticket.phone &&
          (ticket.document || ticket.name || ticket.email) &&
          !ticket.myTicket
      );
      const hasEmailError = !!newTickets.find(
        (ticket) =>
          !ticket.email &&
          (ticket.document || ticket.phone || ticket.name) &&
          !ticket.myTicket
      );

      if (hasDocumentError || hasNameError || hasPhoneError || hasEmailError) {
        throw new Error('has error');
      }

      let i = 0;
      for (const ticket of newTickets) {
        if (ticket.item.ticket?.document || ticket.document) {
          const response = await api.get(
            `tickets/events/${eventId}/${
              ticket.item.ticket?.document || ticket.document
            }`
          );

          if (response.data) {
            const ticketIndex = newTickets.findIndex(
              (ticketData) =>
                ticketData.item.ticket?.document ===
                ticket.item.ticket?.document
            );
            if (ticketIndex >= 0) {
              newTickets[ticketIndex].error = {
                document:
                  ticket.item.ticket?.document ===
                  user.additionalInformation.document
                    ? 'Você já possui um ingresso para este evento'
                    : 'Já existe um ingresso com este CPF para este evento',
              };
            }
          }

          const duplicate = findSameDocument(
            ticket.item.ticket?.document || ticket.document,
            i
          );

          if (duplicate) {
            newTickets[i].error = {
              document:
                ticket.document === user.additionalInformation.document ||
                ticket.item.ticket?.document ===
                  user.additionalInformation.document
                  ? 'Você já preencheu um ingresso com teus dados'
                  : 'Um ingresso já foi preenchido com esses dados',
            };
          }

          if (ticket.myTicket) {
            const formData = {
              user_id: user.id,
              document: ticket.document,
              phone: ticket.phone,
            };
            await api.put(
              `additional-informations/${user.additionalInformation.id}`,
              formData
            );

            updateUser({
              ...user,
              additionalInformation: {
                ...user.additionalInformation,
                document: ticket.document,
                phone: ticket.phone,
              },
            });
          }

          i += 1;
        }
      }

      if (newTickets.find((ticket) => Object.keys(ticket.error).length > 0)) {
        throw new Error('ingresso repetido');
      } else {
        setTickets(newTickets);
      }

      const items: IItem[] = [];
      for (const ticket of newTickets) {
        items.push({
          ...ticket.item,
          ticket: {
            id: new Date().getTime(),
            document: ticket.item.ticket?.document || ticket.document,
            name: ticket.item.ticket?.name || ticket.name,
            email: ticket.item.ticket?.email || ticket.email,
            phone: ticket.item.ticket?.phone || ticket.phone,
          },
        });
      }

      handleChangeItems(items);

      history.push(`${process.env.PUBLIC_URL}/eventos/${params.slug}/resumo`);
    } catch (error) {
      const err = error as any;
      if (err.message === 'has error') {
        for (let index = 0; index < cart.items.length; index += 1) {
          const ticketIndex = newTickets.findIndex(
            (ticket) => ticket.index === index
          );
          if (ticketIndex >= 0) {
            let documentError: string | undefined;
            let nameError: string | undefined;
            let phoneError: string | undefined;
            let emailError: string | undefined;
            if (!newTickets[ticketIndex].document) {
              documentError = 'O documento é obrigatório';
            }
            if (!newTickets[ticketIndex].name) {
              nameError = 'O nome é obrigatório';
            }
            if (!newTickets[ticketIndex].phone) {
              phoneError = 'O telefone é obrigatório';
            }
            if (!newTickets[ticketIndex].email) {
              emailError = 'O email é obrigatório';
            }

            newTickets[ticketIndex].error = {
              ...newTickets[ticketIndex].error,
              document: documentError,
              name: nameError,
              phone: phoneError,
              email: emailError,
            };
          } else {
            newTickets[index] = {
              index,
              item: cart.items[index],
              error: {
                document: 'O documento é obrigatório',
                name: 'O nome é obrigatório',
                phone: 'O telefone é obrigatório',
                email: 'O email é obrigatório',
              },
            };
          }
        }

        setTickets(newTickets);
      } else if (err.message === 'ingresso repetido') {
        setTickets(newTickets);
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
      }
    }
  }, [
    cart.items,
    eventId,
    findSameDocument,
    handleChangeItems,
    history,
    params.slug,
    tickets,
    updateUser,
    user,
  ]);

  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 text-center pt-3">
            <ButtonBack className="btn-back" />
            <div className="banner my-3" />
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h2 className="h4 mb-0 d-flex align-items-center">
                <KrUserDocument
                  color="#FF6900"
                  size={20}
                  className="me-2 signin-icon"
                />
                Titularidade
              </h2>
              <button type="button" className="bg-transparent border-0">
                <KrInfo color="#8C8C8C" size={16} />
              </button>
            </div>
            <h4 className="text-start mb-3">
              Comprou mais de um ingresso? Para agilizar sua compra, pode seguir
              para o pagamento e preencha os dados dos terceiros depois!
            </h4>
          </div>

          <div className="col-11">
            <Form onSubmit={handleSubmit} className="d-flex flex-column pb-3">
              {cart.items.map((item, index) => (
                <div
                  key={item.batch_category_id}
                  className="border-ticket p-3 mb-3"
                >
                  <h3 className="h5 text-black">
                    Ingresso #{(index + 1).toString().padStart(4, '0')}
                  </h3>
                  <p className="pb-3 border-bottom">
                    Modalidade: {item.modality} | Categoria: {item.category}
                  </p>
                  {(index === indexSel || indexSel === -1) && (
                    <InputCheckbox
                      type="checkbox"
                      name={`my_ticket_${index}`}
                      className="my-3 d-block"
                      options={[
                        {
                          label: 'Este ingresso é o meu',
                          value: 'Este ingresso é o meu',
                          selected: index === indexSel,
                        },
                      ]}
                      onChange={() => handleChangeMyTicket(item, index)}
                      error={
                        tickets.find(
                          (ticket) =>
                            ticket.document ===
                            user.additionalInformation.document
                        )?.error?.document
                      }
                    />
                  )}
                  {(index !== indexSel ||
                    !user.additionalInformation.document) && (
                    <label className="w-100 mb-3">
                      <span className="fw-normal d-block mb-2">Documento*</span>
                      <div className="d-flex">
                        <Select
                          className="bd-input w-25"
                          name="documents"
                          readOnly
                          options={[
                            {
                              value: 'CPF',
                              id: 0,
                              selected: true,
                            },
                          ]}
                        />
                        <div className="col mt-0 ms-1">
                          <InputMask
                            kind="cpf"
                            name="document"
                            className="bd-input"
                            onChange={(e) =>
                              handleChangeDocument(e, index, item)
                            }
                            error={
                              tickets.find((ticket) => ticket.index === index)
                                ?.error?.document
                            }
                            value={item.ticket?.document}
                          />
                        </div>
                      </div>
                    </label>
                  )}
                  {(index !== indexSel || !user.name) && (
                    <label className="mb-3 w-100">
                      <span className="fw-normal d-block mb-2">
                        Nome Completo*
                      </span>
                      <Input
                        name="name"
                        className="bd-input"
                        onChange={(e) => handleChangeName(e, index, item)}
                        error={
                          tickets.find((ticket) => ticket.index === index)
                            ?.error?.name
                        }
                        value={item.ticket?.name}
                      />
                    </label>
                  )}
                  {(index !== indexSel ||
                    !user.additionalInformation.phone) && (
                    <label className="mb-3 w-100">
                      <span className="fw-normal d-block mb-2">Telefone*</span>
                      <InputMask
                        kind="cel-phone"
                        name="phone"
                        className="bd-input"
                        onChange={(e) => handleChangePhone(e, index, item)}
                        error={
                          tickets.find((ticket) => ticket.index === index)
                            ?.error?.phone
                        }
                        value={item.ticket?.phone}
                      />
                    </label>
                  )}
                  {(index !== indexSel || !user.email) && (
                    <label className="mb-3 w-100">
                      <span className="fw-normal d-block mb-2 size">
                        E-mail*
                      </span>
                      <Input
                        type="email"
                        name="email"
                        className="bd-input"
                        onChange={(e) => handleChangeEmail(e, index, item)}
                        error={
                          tickets.find((ticket) => ticket.index === index)
                            ?.error?.email
                        }
                        value={item.ticket?.email}
                      />
                    </label>
                  )}
                  {sizes.length > 0 && (
                    <label className="mb-3 w-100">
                      <span className="fw-normal d-block mb-2">
                        Tamanho da camisa
                      </span>

                      <Select
                        className="bd-input select-tam"
                        name="documents"
                        options={sizes}
                      />
                    </label>
                  )}
                </div>
              ))}
              <button
                type="submit"
                className="btn btn-login h6 fw-normal py-2 mb-0"
              >
                Continuar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutOwnership;
