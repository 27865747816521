import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
    color: #8c8c8c;
    padding: 0;
    font-size: 14px;

    ::placeholder {
      color: #8c8c8c;
    }
  }

  span {
    color: #292623;
    font-size: 12px;
    font-weight: 400;
  }

  label span {
    color: #878787 !important;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }

  .bd-input {
    border: 1px solid rgb(225, 225, 225);
    height: 37px;
    border-radius: 4px;
    width: 100% !important;
  }

  .ReactCreditCard__container {
    margin: 0 auto;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    height: auto;
    opacity: 1;
    margin-bottom: 4px;
  }

  .credit-card-data svg:not(.check-icon) {
    display: none;
  }

  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 420px) {
    .ReactCreditCard__container {
      transform: scale(0.9);
      left: -10px;
    }
  }

  @media screen and (max-width: 350px) {
  }
`;
