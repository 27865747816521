import styled from 'styled-components';

export const Container = styled.header`
  .btn-log {
    color: #00a3e0;
  }
  .notification {
    position: absolute;
    top: 31px;
    left: -170px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding: 16px;
    border-radius: 10px;
    width: 218px;
    p {
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .triangle {
    position: absolute;
    top: -8px;
    right: 31px;
    width: 15px;
    height: 15px;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    background-color: #fff;
    transform: rotate(-45deg);
    border-top-right-radius: 3px;
  }
`;
