/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react';
import PinInput from 'react-pin-input';

import { Container } from './styles';
import Input from '../Input';

interface InputCodeProps {
  name: string;
  onChange?(e: any): void;
  value?: string;
  id?: string;
  className?: string;
  error?: string;
  length?: number;
}

const InputCode: React.FC<InputCodeProps> = ({
  name,
  onChange,
  value,
  id,
  className,
  error,
  length = 6,
}) => {
  const [newValue, setNewValue] = useState(value || '');

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        const data = {
          target: {
            name,
            value: e,
          },
        };
        onChange(data);
      }
      setNewValue(e);
    },
    [name, onChange]
  );

  return (
    <>
      <Container id={id} className={className} isErrored={!!error}>
        <PinInput
          initialValue={newValue}
          length={length}
          onChange={handleChange}
          onComplete={handleChange}
          type="numeric"
          autoSelect
        />
        <div className="d-none">
          <Input name={name} value={newValue} />
        </div>
      </Container>
      {error && (
        <span className="small d-block text-center text-danger error">
          {error}
        </span>
      )}
    </>
  );
};

export default InputCode;
