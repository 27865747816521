import styled from 'styled-components';

interface IImgEvent {
  src: string;
  alt?: string;
}

export const Container = styled.div`
  background-color: #f4f0ec;

  .infinite-scroll {
    max-height: 100vh;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
  }

  hr {
    background: #e1e1e1;
  }

  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }
`;

export const Hero = styled.div`
  max-width: 100%;
  overflow: hidden;

  .slick-list {
    transition-duration: 0.3s;
  }
`;

export const Category = styled.div`
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }

  button {
    min-width: 64px;
  }

  button span {
    color: #000;
    font-size: 12px;
    font-weight: 400;
  }

  .w-categories {
    max-width: 560px;
    img {
      width: 48px;
      height: 48px;
    }
  }
`;

export const ImgEvent = styled.div<IImgEvent>`
  height: 150px;
  width: 313px;
  border-radius: 8px 8px 0 0;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #535353;
`;

export const LastEvents = styled.div`
  padding-bottom: 30%;
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }

  h3 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }

  span {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  a span:nth-child(2) {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }

  .view-all {
    color: #ff6900;
    font-size: 12px;
    font-weight: 400;
  }

  .card-events a {
    max-width: 313px;
    background-color: #fff;
    border-radius: 8px;
  }

  .card-events img {
    border-radius: 8px 8px 0 0;
  }

  svg path {
    fill: #878787;
  }
`;
