import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  li {
    margin-bottom: 16px;
  }

  button {
    color: #ff6900;
  }

  .close {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
