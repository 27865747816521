import React from 'react';

import { Modal } from './styles';
import { KrClose } from '~/components/KoroIcons';

interface IProps {
  show: boolean;
  onClose(): void;
}

const ModalTerms: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header className="d-flex align-items-center justify-content-center">
        <button type="button" onClick={onClose} className="close">
          <KrClose color="#878787" size={16} />
        </button>
        <Modal.Title className="h5 fw-bold text-center mt-4 mb-0">
          Termo de Responsabilidade e Compra
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        <p>
          A <b>Koro Esportes Ltda.</b> (doravante "Koro") é uma plataforma
          digital que facilita a venda de ingressos e inscrições para eventos
          esportivos. Não somos responsáveis pela organização, estrutura ou
          ocorrências relacionadas aos eventos oferecidos na plataforma.
        </p>
        <p>
          Declaro que, ao realizar a compra de ingressos ou inscrições, estou
          ciente e de acordo com os seguintes termos:
        </p>
        <ol>
          <li>
            <b>Participação Voluntária:</b> Participo deste evento por minha
            livre e espontânea vontade, isentando os ORGANIZADORES e todas as
            empresas envolvidas no evento de qualquer responsabilidade sobre
            minha participação, em nome próprio e de meus herdeiros.
          </li>
          <li>
            <b>Isenção de Responsabilidade por Pertences:</b> Isento os
            organizadores e empresas relacionadas ao evento de qualquer
            responsabilidade sobre objetos pessoais deixados em guarda-volumes,
            chapelaria ou outros locais indicados pela organização.
          </li>
          <li>
            <b>Política de Cancelamento:</b> Estou ciente de que o valor pago
            não será devolvido em caso de cancelamento da minha participação ou
            não comparecimento, e que não serão permitidas substituições ou
            trocas de participantes.
          </li>
          <li>
            <b>Taxas de Serviço e Processamento:</b> Estou de acordo com a
            cobrança da <b>TAXA DE SERVIÇO e TAXA</b> DE PROCESSAMENTO adicional
            por ingresso ou inscrição adquirida no sistema, caso esta taxa seja
            aplicável ao evento.
          </li>
          <li>
            <b>Disponibilidade de Ingressos:</b> Entendo que a disponibilidade
            de ingressos ou inscrições pode ser encerrada a qualquer momento,
            conforme decisão da organização do evento.
          </li>
          <li>
            <b>Confirmação da Compra:</b> Para garantir minha inscrição ou
            ingresso, devo efetuar o pagamento integral. Caso o pagamento não
            seja realizado ou seja feito em valor divergente (inclusive maior),
            a inscrição ou ingresso será automaticamente cancelado(a), e a vaga
            será liberada.
          </li>
          <li>
            <b>Responsabilidade pelas Informações:</b> É de minha
            responsabilidade obter e verificar todas as informações pertinentes
            ao evento, como data, local e horário, fornecidas pelo organizador.
          </li>
          <li>
            <b>Aptidão Física e Mental:</b> Declaro estar física e mentalmente
            apto(a) a participar deste evento, de acordo com as condições
            estabelecidas pela organização.
          </li>
          <li>
            <b>Autorização de Uso de Imagem:</b> Autorizo o organizador,
            patrocinadores e a Koro a utilizarem imagens, vídeos ou outras
            gravações da minha participação no evento, em qualquer mídia
            (impressa ou digital), incluindo a Internet, sem limitação de tempo
            ou finalidade.
          </li>
          <li>
            <b>Recebimento de Comunicações:</b> Autorizo o recebimento de
            comunicações por e-mail, SMS, WhatsApp e outros meios digitais por
            parte do ORGANIZADOR, Koro e seus parceiros, com informações sobre
            eventos, serviços e promoções.
          </li>
          <li>
            <b>Compra para Terceiros:</b> Ao realizar a compra de ingressos ou
            inscrições para terceiros, declaro ter a autorização desses
            participantes e me responsabilizo pela veracidade dos dados
            fornecidos, garantindo que eles estão cientes e de acordo com este
            Termo e com o <b>REGULAMENTO</b> do evento.
          </li>
          <li>
            <b>Estornos e Reembolsos:</b> Caso seja autorizada a solicitação de
            reembolso, aceito que poderão ser cobradas taxas administrativas
            e/ou custos do processo de estorno.
          </li>
          <li>
            <b>Aceite de Regras e Políticas:</b> Declaro que estou ciente e de
            acordo com o <b>REGULAMENTO</b> ou <b>REGRAS GERAIS</b> do evento,
            bem como com os
            <b>Termos de Uso</b> e <b>Política de Privacidade</b> da plataforma
            Koro.
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <button type="button" onClick={onClose} className="btn close-btn">
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTerms;
