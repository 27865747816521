import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { AuthProvider } from './Auth';
import { CartProvider } from './Cart';
import { ResizeProvider } from './Resize';

const AppProvider: React.FC = ({ children }) => (
  <GoogleOAuthProvider
    clientId={process.env.REACT_APP_OAUTH_CLIENT_ID as string}
  >
    <ResizeProvider>
      <CartProvider>
        <AuthProvider>{children}</AuthProvider>
      </CartProvider>
    </ResizeProvider>
  </GoogleOAuthProvider>
);

export default AppProvider;
