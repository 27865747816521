import styled from 'styled-components';

export const Container = styled.div`
  background: #f4f0ec;
  padding-bottom: 30%;
  min-height: 100vh;

  h1 {
    color: #ff6900;
    font-family: 'Poppins';
    font-size: 26px;
    font-weight: 500;
    line-height: 108%;
  }

  .card-events a {
    background-color: #fff;
    border-radius: 8px;
  }

  .card-events img {
    border-radius: 8px 8px 0 0;
  }

  .card-events a span:nth-child(2) {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }

  .card-events span {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .bg-white {
    border-radius: 8px;
  }

  .input-search {
    flex: 1;
  }
`;

export const Menu = styled.div`
  a {
    color: #8c8c8c;

    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    border: none;
    border-bottom: 2px solid transparent;
    :hover {
      color: #002f6c;
      font-weight: 600;
      border-bottom: 2px solid #002f6c;
    }
  }

  .active {
    color: #002f6c;
    font-weight: 600;
    border-bottom: 2px solid #002f6c;
  }

  .bg {
    border-radius: 8px;
    background: #fff;
    padding: 23.5px 0;
    width: 100%;
  }
`;
