import React from 'react';
import { IProps } from './interfaces';

const KrEyeSlash: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.57535 3.82924C4.08904 4.31554 4.45357 5.45817 5.39555 6.40014L25.7798 26.7844C26.7218 27.7264 27.8644 28.0909 28.3507 27.6046C28.837 27.1183 28.4725 25.9757 27.5305 25.0337L7.14625 4.64943C6.20428 3.70746 5.06166 3.34293 4.57535 3.82924Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19837 9.73723C6.71663 10.0471 6.24648 10.3847 5.79 10.75L0.399999 15.07C0.149999 15.27 0.0299986 15.59 0.0699986 15.89C0.0299986 16.19 0.149999 16.5 0.399999 16.71L5.8 21.03C8.8 23.44 12.4 24.64 15.99 24.64C17.7576 24.64 19.5276 24.3491 21.2272 23.7661L19.7798 22.3187C15.4238 23.5136 10.6556 22.6141 6.92 19.62L2.26 15.88L6.92 12.14C7.4276 11.7331 7.95436 11.365 8.49662 11.0355L7.19837 9.73723ZM13.3522 9.17429L11.8579 7.67998C16.721 6.39333 22.022 7.41667 26.18 10.75L31.58 15.07V15.08C31.8 15.25 31.92 15.51 31.92 15.78V16.01C31.92 16.28 31.79 16.54 31.58 16.71L26.18 21.03C25.9991 21.1753 25.816 21.3163 25.6308 21.4528L24.336 20.1581C24.5778 19.9869 24.8159 19.8076 25.05 19.62L29.71 15.88L25.05 12.14C21.6498 9.40961 17.3827 8.42105 13.3522 9.17429ZM11.3323 13.8712C11.0355 14.5199 10.87 15.2409 10.87 16C10.87 18.83 13.17 21.13 16 21.13C16.7591 21.13 17.4801 20.9645 18.1288 20.6677L16.9293 19.4681C16.6328 19.5476 16.3213 19.59 16 19.59C14.02 19.59 12.41 17.98 12.41 16C12.41 15.6787 12.4524 15.3672 12.5319 15.0707L11.3323 13.8712ZM16.6462 12.4682L15.1229 10.9449C15.4079 10.8957 15.701 10.87 16 10.87C18.83 10.87 21.13 13.17 21.13 16C21.13 16.299 21.1043 16.5921 21.0551 16.8771L19.5318 15.3538C19.265 13.891 18.109 12.735 16.6462 12.4682Z"
        fill={color}
      />
    </svg>
  );
};

export default KrEyeSlash;
