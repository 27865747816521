import React from 'react';
import { IProps } from './interfaces';

const KrFilter: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.05 5.25H0.949988C0.449988 5.25 0.0499878 5.65 0.0499878 6.15C0.0499878 6.65 0.449988 7.05 0.949988 7.05H31.06C31.56 7.05 31.96 6.65 31.96 6.15C31.96 5.65 31.56 5.25 31.06 5.25H31.05Z"
        fill={color}
      />
      <path
        d="M27.77 11.82H4.22999C3.72999 11.82 3.32999 12.22 3.32999 12.72C3.32999 13.22 3.72999 13.62 4.22999 13.62H27.77C28.27 13.62 28.67 13.22 28.67 12.72C28.67 12.22 28.27 11.82 27.77 11.82Z"
        fill={color}
      />
      <path
        d="M24.14 18.38H7.85999C7.35999 18.38 6.95999 18.78 6.95999 19.28C6.95999 19.78 7.35999 20.18 7.85999 20.18H24.13C24.63 20.18 25.03 19.78 25.03 19.28C25.03 18.78 24.63 18.38 24.13 18.38H24.14Z"
        fill={color}
      />
      <path
        d="M19.9 24.95H12.1C11.6 24.95 11.2 25.35 11.2 25.85C11.2 26.35 11.6 26.75 12.1 26.75H19.9C20.4 26.75 20.8 26.35 20.8 25.85C20.8 25.35 20.4 24.95 19.9 24.95Z"
        fill={color}
      />
    </svg>
  );
};

export default KrFilter;
