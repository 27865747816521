import React from 'react';

import { Avatar } from './styles';

interface IAvatarProfile {
  src: string;
}

const AvatarProfile: React.FC<IAvatarProfile> = ({ src }) => {
  return <Avatar src={src} />;
};

export default AvatarProfile;
