import styled from 'styled-components';

interface IContainer {
  active: boolean;
}

export const Container = styled.div<IContainer>`
  button {
    width: 200px;
  }
  .anim-lottie svg path {
    fill: #002f6c !important;
    stroke: #fff !important;
  }
  p a {
    color: #002f6c;
  }
`;
