import styled from 'styled-components';
import banner from '~/assets/banners/banner-confirm-account.png';

export const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  h1 {
    color: #292623;
  }

  h3 {
    color: #292623;
    font-family: 'Roboto';
  }

  p {
    color: #8c8c8c;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .bd-mod {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
  }

  .bd-mod span {
    font-size: 14px;
    color: #8c8c8c;
    font-weight: 400;
  }

  .qtd-kits {
    border: 1px solid #e1e1e1;
    padding: 5px 3px;
    width: 30px;
    height: 30px;
  }

  .qtd-kits input {
    color: #292623;
    text-align: center;
    font-size: 14px;
  }

  .qtd-kits svg {
    display: none;
  }

  .bg-category {
    border-radius: 8px 8px 0px 0px;
    background: #ffe9da;
  }

  .banner {
    background-image: url(${banner});
    height: 72px;
    width: 100%;
    border-radius: 8px;
    background-size: cover;
    background-position-y: 23%;
    background-repeat: no-repeat;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .bd-input {
    border: 1px solid #e1e1e1;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #5a5a5a;
      transition-duration: 0.2s;
      font-weight: 500;

      ::placeholder {
        color: #5a5a5a;
      }
    }
  }

  svg:not(.g-icon, .signin-icon) path {
    fill: #5a5a5a;
  }
`;
