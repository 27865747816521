import React from 'react';
import { IProps } from './interfaces';

const KrHome: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2.95L29.75 14.66H29.6C27.62 14.66 26 16.27 26 18.26V29.62H21.45V22.75C21.45 20.77 19.84 19.15 17.85 19.15H14.15C12.17 19.15 10.55 20.76 10.55 22.75V29.62H6V18.26C6 16.28 4.39 14.66 2.4 14.66H2.22L16 2.95ZM16 1.15C15.59 1.15 15.17 1.29 14.83 1.58L1.06 13.28C-0.219999 14.36 0.550001 16.45 2.23 16.45H2.41C3.4 16.45 4.21 17.26 4.21 18.25V29.61C4.21 30.6 5.02 31.41 6.01 31.41H10.56C11.55 31.41 12.36 30.6 12.36 29.61V22.74C12.36 21.75 13.17 20.94 14.16 20.94H17.86C18.85 20.94 19.66 21.75 19.66 22.74V29.61C19.66 30.6 20.47 31.41 21.46 31.41H26.01C27 31.41 27.81 30.6 27.81 29.61V18.25C27.81 17.26 28.62 16.45 29.61 16.45H29.76C31.43 16.45 32.2 14.36 30.93 13.28L17.17 1.58C16.83 1.29 16.42 1.15 16 1.15Z"
        fill={color}
      />
    </svg>
  );
};

export default KrHome;
