import styled from 'styled-components';

export const Container = styled.div`
  .infinite-scroll {
    max-height: calc(100vh - 365px);
    overflow: auto;

    .padding-bs {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    } */
  }

  h2 {
    color: #393939;

    font-family: 'Roboto';
  }

  img {
    width: 100px;
    height: 100px;
  }

  p {
    color: #a9a9a9;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    border-radius: 8px;
    border: 1px solid #a9a9a9;
  }

  .title-h2 {
    height: 32px;
  }

  .circles {
    right: 130px;
    width: 1px;
  }

  .circle-top {
    position: absolute;
    width: 20px;
    height: 10px;
    top: -18px;
    //border-radius: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 2px solid #dfdfdf;
    border-right: 2px solid #dfdfdf;
    border-left: 2px solid #dfdfdf;
    background: #f4f0ec;
  }

  .circle-bottom {
    position: absolute;
    width: 20px;
    height: 10px;
    bottom: -18px;
    //border-radius: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 2px solid #dfdfdf;
    border-right: 2px solid #dfdfdf;
    border-left: 2px solid #dfdfdf;
    background: #f4f0ec;
  }

  .circle-bottom.white-bg {
    background: #fff;
  }

  .btn-circle-bottom {
    bottom: -18px;
  }

  .small-circle {
    position: relative;
    left: 6px;
    border: 1px solid #dfdfdf;
    width: 6px;
    height: 6px;
    background: #f4f0ec;
    border-radius: 10px;
    margin-top: 9px;
    //margin-left: 6px;
  }
  .bg-ticket {
    border-radius: 16px;
    border: 2px solid #dfdfdf;

    background: #fff;
  }

  .left-side {
    color: #878787;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
  }

  .right-side {
    color: #393939;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    max-width: 294px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 34vw;
      max-width: 285px;
    }
  }

  .front {
    position: relative;
    z-index: 4;
  }

  .behind1 {
    margin-top: -163px;
    position: relative;
    z-index: 2;
  }

  .behind2 {
    margin-top: -163px;
    position: relative;
    z-index: 1;
  }

  @media screen and (max-width: 430px) {
    .right-side span {
      width: 28vw;
    }
  }
`;
