import React from 'react';
import { IProps } from './interfaces';

const KrHanger: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.79 21.09L16.89 15.54C16.89 15.54 16.89 15.53 16.89 15.52V15.07C16.89 14.68 17.16 14.34 17.58 14.2C19.93 13.39 21.28 10.93 20.72 8.48C20.31 6.7 18.9 5.29 17.13 4.89C15.65 4.55 14.14 4.89001 12.97 5.82001C11.81 6.75001 11.14 8.13001 11.14 9.62001C11.14 10.12 11.54 10.52 12.04 10.52C12.54 10.52 12.94 10.12 12.94 9.62001C12.94 8.69001 13.36 7.81001 14.09 7.23001C14.83 6.64001 15.77 6.43001 16.72 6.65001C17.81 6.90001 18.71 7.80001 18.96 8.89001C19.31 10.44 18.47 12 16.99 12.51C15.85 12.9 15.09 13.94 15.09 15.09V15.5L1.2 21.09C0.47 21.38 0 22.09 0 22.87V25.27C0 26.33 0.86 27.19 1.92 27.19H30.08C31.14 27.19 32 26.33 32 25.27V22.87C32 22.09 31.52 21.38 30.79 21.09ZM30.2 25.27C30.2 25.34 30.15 25.39 30.08 25.39H1.92C1.85 25.39 1.8 25.34 1.8 25.27V22.87C1.8 22.87 1.83 22.78 1.87 22.76L15.92 17.09L30.12 22.76C30.12 22.76 30.19 22.82 30.19 22.87V25.27H30.2Z"
        fill={color}
      />
    </svg>
  );
};

export default KrHanger;
