import React from 'react';
import { IProps } from './interfaces';

const KrTrash: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.84 10.63C11.34 10.67 10.97 11.1 11.01 11.6L11.95 23.99C11.99 24.46 12.38 24.82 12.85 24.82C12.87 24.82 12.9 24.82 12.92 24.82C13.42 24.78 13.79 24.35 13.75 23.85L12.81 11.46C12.77 10.96 12.34 10.59 11.84 10.63Z"
        fill={color}
      />
      <path
        d="M20.16 10.63C19.66 10.59 19.23 10.96 19.19 11.46L18.25 23.85C18.21 24.35 18.58 24.78 19.08 24.82C19.1 24.82 19.13 24.82 19.15 24.82C19.62 24.82 20.01 24.46 20.05 23.99L20.99 11.6C21.03 11.1 20.66 10.67 20.16 10.63Z"
        fill={color}
      />
      <path
        d="M29.93 4.78999H22.16V3.87999C22.16 1.79999 20.47 0.109985 18.39 0.109985H13.63C11.55 0.109985 9.86 1.79999 9.86 3.87999V4.78999H2.07C1.57 4.78999 1.17 5.18999 1.17 5.68999C1.17 6.18999 1.57 6.58999 2.07 6.58999H4.26L6.75 31.09C6.8 31.55 7.18 31.9 7.65 31.9H24.36C24.82 31.9 25.21 31.55 25.26 31.09L27.75 6.58999H29.94C30.44 6.58999 30.84 6.18999 30.84 5.68999C30.84 5.18999 30.44 4.78999 29.94 4.78999H29.93ZM11.65 3.87999C11.65 2.78999 12.54 1.90999 13.62 1.90999H18.38C19.47 1.90999 20.35 2.79999 20.35 3.87999V4.78999H11.64V3.87999H11.65ZM23.54 30.1H8.46L6.07 6.58999H25.92L23.53 30.1H23.54Z"
        fill={color}
      />
    </svg>
  );
};

export default KrTrash;
