import styled, { css } from 'styled-components';
import banner from '~/assets/banners/banner-signup.png';

interface IBar {
  percent: number;
}

export const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  h1 {
    color: #ff6900;
    font-size: 26px;
  }
  h2 {
    font-family: 'Roboto';
  }

  label span {
    color: #292623;
    font-size: 12px;
  }

  p span {
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 400;
  }

  p a {
    color: #00a3e0;
    font-size: 14px;
    font-weight: 400;
  }

  .or {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
  }

  .banner {
    background-image: url(${banner});
    height: 100px;
    width: 100%;
    border-radius: 8px;
    background-size: cover;
    background-position: 0px -70px;
    background-repeat: no-repeat;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .bd-input {
    border: 1px solid #e1e1e1;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #8c8c8c;
      transition-duration: 0.2s;

      ::placeholder {
        color: #8c8c8c;
      }
    }
  }

  svg:not(.g-icon) path {
    fill: #5a5a5a;
  }
`;

export const Bar = styled.div<IBar>`
  width: 250px;
  height: 5px;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;

  ::after {
    content: '';
    width: ${(props) => props.percent}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    transition-duration: 0.3s;
  }

  ${(props) => {
    if (props.percent === 40) {
      return css`
        border-color: #ec4810;

        ::after {
          background-color: #ec4810;
        }
      `;
    }
    if (props.percent === 60) {
      return css`
        border-color: #ff7a00;

        ::after {
          background-color: #ff7a00;
        }
      `;
    }
    if (props.percent === 80) {
      return css`
        border-color: #ffa800;

        ::after {
          background-color: #ffa800;
        }
      `;
    }
    if (props.percent === 100) {
      return css`
        border-color: #00b15c;

        ::after {
          background-color: #00b15c;
        }
      `;
    }

    return css`
      border-color: #ff003d;

      ::after {
        background-color: #ff003d;
      }
    `;
  }}
`;
