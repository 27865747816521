import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import ButtonBack from '~/components/ButtonBack';
import Success from '~/components/Success';
import Input from '~/components/Input';

interface IFormData {
  password: string;
}

interface IParams {
  token: string;
}

const ChangePassword: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [change, setChange] = useState(true);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('A senha é obrigatório'),
          confirm_password: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas devem ser iguais'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          token: params.token,
          password: data.password,
        };

        await api.post('users/sessions/change-password', formData);

        setChange(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [params.token]
  );

  return (
    <Container>
      <div className="container">
        {change ? (
          <div className="row justify-content-center">
            <div className="col-11 text-center pt-3">
              <ButtonBack className="mt-3" />
              <div className="banner my-3" />
              <h1 className="text-start mb-3">Alterando sua senha</h1>
            </div>

            <div className="col-11 px-3">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="d-flex flex-column pb-3"
              >
                <label className="mb-3">
                  <span className="fw-normal d-block mb-2">Nova senha</span>
                  <Input type="password" name="password" className="bd-input" />
                </label>

                <label className="">
                  <span className="fw-normal d-block mb-2">
                    Confirme a nova senha
                  </span>
                  <Input
                    type="password"
                    name="confirm-password"
                    className="bd-input"
                  />
                </label>

                <button
                  type="submit"
                  className="btn btn-login my-4 h6 fw-normal py-2"
                >
                  Alterar
                </button>
                <p className="text-center">
                  Lembrou sua senha? Faça login{' '}
                  <Link to={`${process.env.PUBLIC_URL}/login`}>aqui</Link> .
                </p>
              </Form>
            </div>
          </div>
        ) : (
          <div className="row flex-column justify-content-center align-items-center vh-100">
            <div className="col-10 text-center">
              <Success
                active={!change}
                title="Senha alterada com sucesso!"
                text="Sua conta foi recuperada, utilize sua nova senha para acessar a Koro."
                textButton="Entrar"
                onBtnClick={() =>
                  history.push(`${process.env.PUBLIC_URL}/login`)
                }
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ChangePassword;
