import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  min-height: 100vh;
  background-color: #fff;
  h1 {
    color: #ff6900;
    font-size: 26px;
  }

  h2 {
    color: #ff6900;
    font-family: 'Poppins';
    img {
      width: 20px;
      height: 20px;
    }
  }

  label span {
    color: #8c8c8c;
    font-size: 13px;
    font-weight: 400;
  }

  h4 {
    color: #878787;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
  }

  p {
    color: #8c8c8c;
    font-size: 13px;
    font-weight: 400;
  }

  li span:nth-child(1) {
    color: #292623;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
  }

  li span:nth-child(2) {
    color: #8c8c8c;
    font-size: 13px;
    font-weight: 400;
  }

  .bd-discount {
    border-radius: 0px 0px 4px 4px;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    input {
      color: #292623;
      font-size: 14px;
      font-weight: 400;

      ::placeholder {
        color: #29262355;
      }
    }
    svg:not(.check-icon) {
      display: none;
    }
  }

  .tooltip-tax:after {
    background-color: #8c8c8c;
    color: #fff;
    border: 1px solid #8c8c8c;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 12px;
    position: absolute;
    right: 10px;
    bottom: 32px;
    width: calc(100% - 20px);
    z-index: 10000;
    text-align: justify;
  }

  .tooltip-tax:hover:after {
    display: block;
  }

  .btn-back {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }

  .bd-discount div {
    border-color: rgba(255, 255, 255, 0.5) !important;
  }

  .discount .title {
    border-radius: 4px 4px 0px 0px;
    background: #ffe9da;
    color: #292623;
    font-size: 14px;
    font-weight: 400;
  }

  .check label > div {
    margin-right: 16px;
  }

  .check span button {
    color: #292623;
    font-size: 13px;
    font-weight: 400;
    border: none;
    background-color: transparent;
  }

  .border-ticket {
    border-radius: 8px;
    border: 1px solid #e1e1e1;

    background: #fff;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .bd-input {
    border: 1px solid #e1e1e1;
    height: 40px;
    border-radius: 4px;

    input {
      width: 100%;
      flex: 1;
      background: transparent;
      border: 0;
      color: #000;
      transition-duration: 0.2s;

      ::placeholder {
        color: #8c8c8c;
      }
    }
    svg:not(.check-icon, .rotate-arrow) {
      display: none;
    }
  }

  .values p {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
  }

  .dot {
    border-bottom: 2px dotted #8c8c8c;
    flex: 1;
    height: 15px;
    margin: 0 8px;
  }

  .color-desc {
    color: #00b15c;
  }

  .total {
    color: #292623 !important;
    text-align: right;
    font-size: 20px !important;
    font-weight: 400;
    span {
      color: #ff7a00;
      font-weight: 700;
    }
  }

  .select-tam input {
    font-size: 16px;
  }
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .copy-btn {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 12px;
    border: none;
  }
`;
