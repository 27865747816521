import React from 'react';
import { IProps } from './interfaces';

const KrSignout: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3457 1.80114L28.7469 1.82039C29.2631 1.82103 29.6857 2.23156 29.6851 2.73156L29.65 29.0415C29.6494 29.5415 29.2256 29.951 28.7095 29.9504L13.3185 29.9311C12.8024 29.9305 12.389 30.33 12.3883 30.83C12.3876 31.33 12.8 31.7305 13.3161 31.7311L28.7071 31.7504C30.2451 31.7523 31.5061 30.5339 31.5081 29.0439L31.5431 2.73388C31.5451 1.23389 30.2874 0.0223118 28.7493 0.0203898L13.3584 0.00115696C12.8422 0.000512 12.4288 0.399995 12.4281 0.899995C12.4275 1.39999 12.8399 1.80051 13.356 1.80116L13.3457 1.80114Z"
        fill={color}
      />

      <path
        d="M20.6761 15.91C20.6761 15.88 20.6658 15.85 20.6555 15.83C20.6555 15.81 20.6555 15.8 20.6555 15.78C20.6555 15.75 20.6555 15.73 20.6452 15.7C20.6452 15.7 20.6452 15.7 20.6452 15.69C20.6245 15.65 20.6039 15.61 20.5729 15.58C20.5523 15.55 20.5419 15.52 20.5213 15.5L14.4413 9.25C14.2039 9.01 13.8116 9 13.5639 9.22C13.3161 9.45 13.3058 9.83 13.5432 10.07L18.6426 15.32H0.320001C-0.0206445 15.32 -0.299355 15.59 -0.299355 15.92C-0.299355 16.25 -0.0206445 16.52 0.320001 16.52H18.4877L13.5639 21C13.3161 21.23 13.3058 21.61 13.5329 21.85C13.6568 21.97 13.8219 22.04 13.9871 22.04C14.1419 22.04 14.2865 21.99 14.4103 21.88L20.48 16.36C20.511 16.33 20.5419 16.28 20.5626 16.24C20.5729 16.22 20.6039 16.2 20.6142 16.17C20.6245 16.15 20.6245 16.12 20.6348 16.09C20.6348 16.06 20.6555 16.03 20.6555 16C20.6555 15.98 20.6658 15.97 20.6658 15.95C20.6658 15.95 20.6658 15.95 20.6658 15.94L20.6761 15.91Z"
        fill={color}
      />
    </svg>
  );
};

export default KrSignout;
