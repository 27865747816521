import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { Container, PoweredBy } from './styles';
import devsigner from '~/assets/logos/devsigner.svg';
import { KrHome, KrSearch, KrTicket, KrUser } from '../KoroIcons';
import { useAuth } from '~/hooks/Auth';

const Footer: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <>
      <Container className="w-100">
        <div className="bg d-flex justify-content-around mx-3">
          <NavLink
            to={`${process.env.PUBLIC_URL}/`}
            activeClassName="opacity-100"
            exact
          >
            <KrHome size={22} color="#fff" />
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/buscar-eventos`}
            activeClassName="opacity-100"
          >
            <KrSearch size={22} color="#fff" />
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/${
              user ? 'meus-ingressos/proximos' : 'login'
            }`}
            activeClassName="opacity-100"
            className={
              location.pathname === '/meus-ingressos/realizados' ||
              location.pathname === '/meus-ingressos/cancelados'
                ? 'opacity-100'
                : ''
            }
          >
            <KrTicket size={22} color="#fff" />
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/${user ? 'perfil' : 'login'}`}
            activeClassName="opacity-100"
          >
            <KrUser size={22} color="#fff" />
          </NavLink>
        </div>
      </Container>
      {false && (
        <PoweredBy className="d-flex justify-content-center py-3">
          <small className="text-center text-lg-start mt-3 mt-lg-0">
            Desenvolvido por:{' '}
            <a
              href="https://devsigner.com.br/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              <img src={devsigner} alt="Devsigner Systems" />
            </a>
          </small>
        </PoweredBy>
      )}
    </>
  );
};

export default Footer;
