import React from 'react';
import { IProps } from './interfaces';

const KrEye: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.58 15.07L26.18 10.75C20.18 5.94 11.8 5.94 5.79 10.75L0.399999 15.07C0.149999 15.27 0.0299986 15.59 0.0699986 15.89C0.0299986 16.19 0.149999 16.5 0.399999 16.71L5.8 21.03C8.8 23.44 12.4 24.64 15.99 24.64C19.58 24.64 23.18 23.44 26.18 21.03L31.58 16.71C31.79 16.54 31.92 16.28 31.92 16.01V15.78C31.92 15.51 31.8 15.25 31.58 15.08V15.07ZM25.05 19.62C19.71 23.9 12.26 23.9 6.92 19.62L2.26 15.88L6.92 12.14C12.26 7.86 19.72 7.86 25.05 12.14L29.71 15.88L25.05 19.62Z"
        fill={color}
      />
      <path
        d="M16 10.87C13.17 10.87 10.87 13.17 10.87 16C10.87 18.83 13.17 21.13 16 21.13C18.83 21.13 21.13 18.83 21.13 16C21.13 13.17 18.83 10.87 16 10.87ZM16 19.59C14.02 19.59 12.41 17.98 12.41 16C12.41 14.02 14.02 12.41 16 12.41C17.98 12.41 19.59 14.02 19.59 16C19.59 17.98 17.98 19.59 16 19.59Z"
        fill={color}
      />
    </svg>
  );
};

export default KrEye;
