import styled from 'styled-components';

interface IAvatar {
  src: string;
}

export const Avatar = styled.div<IAvatar>`
  width: 32px;
  height: 32px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100px;
  border: 1px solid #ca4a02;
`;
