import React from 'react';
import { IProps } from './interfaces';

const KrSignout: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.68 29.97H2.76C2.26 29.97 1.85 29.56 1.85 29.06V2.75C1.85 2.25 2.26 1.84 2.76 1.84H17.67C18.17 1.84 18.57 1.44 18.57 0.940001C18.57 0.440001 18.17 0.0400009 17.67 0.0400009H2.76C1.27 0.0400009 0.0500031 1.26 0.0500031 2.75V29.06C0.0500031 30.56 1.27 31.77 2.76 31.77H17.67C18.17 31.77 18.57 31.37 18.57 30.87C18.57 30.37 18.17 29.97 17.67 29.97H17.68Z"
        fill={color}
      />
      <path
        d="M29.03 15.91C29.03 15.88 29.02 15.85 29.01 15.83C29.01 15.81 29.01 15.8 29.01 15.78C29.01 15.75 29.01 15.73 29 15.7C29 15.7 29 15.7 29 15.69C28.98 15.65 28.96 15.61 28.93 15.58C28.91 15.55 28.9 15.52 28.88 15.5L22.99 9.25C22.76 9.01 22.38 9 22.14 9.22C21.9 9.45 21.89 9.83 22.12 10.07L27.06 15.32H9.31C8.98 15.32 8.71 15.59 8.71 15.92C8.71 16.25 8.98 16.52 9.31 16.52H26.91L22.14 21C21.9 21.23 21.89 21.61 22.11 21.85C22.23 21.97 22.39 22.04 22.55 22.04C22.7 22.04 22.84 21.99 22.96 21.88L28.84 16.36C28.87 16.33 28.9 16.28 28.92 16.24C28.93 16.22 28.96 16.2 28.97 16.17C28.98 16.15 28.98 16.12 28.99 16.09C28.99 16.06 29.01 16.03 29.01 16C29.01 15.98 29.02 15.97 29.02 15.95C29.02 15.95 29.02 15.95 29.02 15.94L29.03 15.91Z"
        fill={color}
      />
    </svg>
  );
};

export default KrSignout;
