import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { addHours, format, parseISO } from 'date-fns';
import axios from 'axios';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { cpf } from 'cpf-cnpj-validator';

import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Toast from '~/utils/toast';
import InputRadio, { IOption } from '~/components/InputRadio';

interface IFormData {
  id: number;
  name: string;
  email: string;
  document?: string;
  phone?: string;
  birthdate?: string;
  gender?: string;
  address_id?: number;
  zipcode?: string;
  street?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  complement?: string;
}

const MyProfile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [userData, setUserData] = useState({} as IFormData);
  const [genderSelected, setGenderSelected] = useState({} as IOption);

  useEffect(() => {
    setUserData({
      id: user.id,
      name: user.name,
      email: user.email,
      document: user.additionalInformation.document,
      phone: user.additionalInformation.phone,
      birthdate: user.additionalInformation.birthdate
        ? format(
            addHours(parseISO(user.additionalInformation.birthdate), 3),
            'yyyy-MM-dd'
          )
        : undefined,
      gender: user.additionalInformation.gender,
      address_id: user.additionalInformation.address?.id,
      zipcode: user.additionalInformation.address
        ? user.additionalInformation.address.zip_code
        : undefined,
      street: user.additionalInformation.address
        ? user.additionalInformation.address.street
        : undefined,
      number: user.additionalInformation.address
        ? user.additionalInformation.address.number?.toString()
        : undefined,
      neighborhood: user.additionalInformation.address
        ? user.additionalInformation.address.neighborhood
        : undefined,
      city: user.additionalInformation.address
        ? user.additionalInformation.address.city
        : undefined,
      state: user.additionalInformation.address
        ? user.additionalInformation.address.state
        : undefined,
      complement: user.additionalInformation.address
        ? user.additionalInformation.address.complement
        : undefined,
    });
    if (user.additionalInformation.gender) {
      setGenderSelected({
        id: user.additionalInformation.gender,
        value:
          user.additionalInformation.gender === 'M' ? 'Masculino' : 'Feminino',
      });
    }
  }, [user]);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setUserData((state) => ({
        ...state,
        street: response.data.logradouro,
        neighborhood: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf,
        complement: response.data.complement,
      }));
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string().required('O e-mail é obrigatório'),
          document: Yup.string()
            .test('cpf', 'CPF inválido', (value) =>
              cpf.isValid(value as string)
            )
            .required('O CPF é obrigatório')
            .nullable(),
          phone: Yup.string().required('O telefone é obrigatório').nullable(),
          birthdate: Yup.string().required('O nascimento é obrigatório'),
          gender: Yup.string().required('O gênero é obrigatório'),
          zipcode: Yup.string().required('O CEP é obrigatório'),
          street: Yup.string().required('A rua é obrigatória'),
          number: Yup.string().required('O número é obrigatório'),
          neighborhood: Yup.string().required('O bairro é obrigatório'),
          city: Yup.string().required('A cidade é obrigatória'),
          state: Yup.string().required('O estado é obrigatório'),
          complement: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          document,
          phone,
          birthdate,
          gender,
          zipcode,
          street,
          number,
          neighborhood,
          state,
          city,
          complement,
        } = data;

        const formData = {
          name,
          email,
        };
        await api.put(`users/${user.id}`, formData);

        const addressFormData = {
          zip_code: zipcode,
          street,
          number,
          neighborhood,
          city,
          state,
          complement,
        };

        let address_id = user.additionalInformation.address?.id;
        if (user.additionalInformation.address) {
          const response = await api.put(
            `addresses/${user.additionalInformation.address.id}`,
            addressFormData
          );
          address_id = response.data.id;
        } else {
          const response = await api.post(`addresses`, addressFormData);
          address_id = response.data.id;
        }

        const athleteFormData = {
          user_id: user.id,
          address_id,
          document,
          phone,
          birthdate,
          gender,
        };
        await api.put(
          `additional-informations/${user.additionalInformation.id}`,
          athleteFormData
        );

        updateUser({
          ...user,
          id: user.id,
          name,
          email,
          additionalInformation: {
            ...user.additionalInformation,
            id: user.additionalInformation.id,
            document,
            phone,
            birthdate,
            gender: genderSelected.id.toString(),
            verified: user.additionalInformation.verified,
            created_at: user.additionalInformation.created_at,
            address: {
              ...user.additionalInformation.address,
              id: address_id,
              zip_code: zipcode || '',
              street: street || '',
              number,
              neighborhood: neighborhood || '',
              state: state || '',
              city: city || '',
              complement: complement || '',
              country: user.additionalInformation.address
                ? user.additionalInformation.address.country
                : 'Brasil',
            },
          },
        });

        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Dados salvos!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [genderSelected, updateUser, user]
  );

  return (
    <Container className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg p-3 mt-3">
            <Form
              ref={formRef}
              initialData={userData}
              onSubmit={handleSubmit}
              className="d-flex flex-column"
            >
              <h2 className="mb-3">Dados pessoais</h2>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Nome completo</span>
                <Input name="name" className="bd-input" />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">E-mail</span>
                <Input name="email" type="email" className="bd-input" />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">CPF</span>
                <InputMask
                  kind="custom"
                  options={{ mask: '999.999.999-99' }}
                  name="document"
                  className="bd-input"
                  value={userData.document}
                />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Telefone</span>
                <InputMask
                  kind="cel-phone"
                  name="phone"
                  className="bd-input"
                  value={userData.phone}
                />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">
                  Data de nascimento
                </span>
                <Input
                  type="date"
                  name="birthdate"
                  className="bd-input"
                  defaultValue={userData.birthdate}
                />
              </label>
              <label className="">
                <span className="fw-normal d-block mb-2">Gênero</span>
                <InputRadio
                  name="gender"
                  className="d-flex justify-content-start check"
                  options={[
                    {
                      id: 'M',
                      value: 'Masculino',
                    },
                    {
                      id: 'F',
                      value: 'Feminino',
                    },
                  ]}
                  selected={genderSelected}
                  onChange={(e) => setGenderSelected(e)}
                />
              </label>
              <hr />
              <h2 className="mt-2 mb-3">Endereço</h2>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">CEP</span>
                <InputMask
                  kind="custom"
                  options={{ mask: '99999-999' }}
                  name="zipcode"
                  className="bd-input"
                  value={userData.zipcode}
                  onChange={handleChangeZipCode}
                />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Endereço</span>
                <Input name="street" className="bd-input" />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Numero</span>
                <Input name="number" className="bd-input" />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Bairro</span>
                <Input name="neighborhood" className="bd-input" />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Cidade</span>
                <Input name="city" className="bd-input" />
              </label>
              <label className="mb-3">
                <span className="fw-normal d-block mb-2">Estado</span>
                <Input name="state" className="bd-input" />
              </label>
              <label className="">
                <span className="fw-normal d-block mb-2">Complemento</span>
                <Input name="complement" className="bd-input" />
              </label>
              <button
                type="submit"
                className="w-100 btn btn-login h6 fw-normal py-2 mt-4"
              >
                Salvar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyProfile;
