import React from 'react';
import { IProps } from './interfaces';

const KrGoogle: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1860)">
        <path
          d="M18 14.4V18.4H27.44C26.48 24 22 28 16.4 28C9.84 28 4.4 22.56 4.4 16C4.4 9.44 9.84 4 16.4 4C19.76 4 22.64 5.44 24.72 7.68L27.6 4.8C24.72 1.92 20.88 0 16.4 0C7.6 0 0.400002 7.2 0.400002 16C0.400002 24.8 7.6 32 16.4 32C25.2 32 31.6 24.8 31.6 16V14.4H18Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1860">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrGoogle;
