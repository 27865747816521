/* eslint-disable react/no-danger */
import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';

import success from '~/assets/animations/success.json';

interface ISuccess {
  active: boolean;
  title?: string;
  text: string;
  textButton?: string;
  onBtnClick?(): void;
}

const Success: React.FC<ISuccess> = ({
  active,
  title,
  text,
  textButton,
  onBtnClick,
}) => {
  return (
    <Container active={active}>
      <div className="anim-lottie mt-n5">
        <Lottie
          options={{
            animationData: success,
            autoplay: true,
            loop: false,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={250}
          width={250}
        />
      </div>
      <h2 className="h2 fw-bold mt-n5">{title}</h2>
      <p
        className="text-center my-4"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <button
        type="button"
        onClick={onBtnClick}
        className="btn btn-login mb-3 h6 fw-normal py-2 px-5"
      >
        {textButton}
      </button>
    </Container>
  );
};

export default Success;
