import React, { createContext, useCallback, useState, useContext } from 'react';

interface ITicket {
  id: number;
  document?: string;
  name?: string;
  phone?: string;
  email?: string;
}

export interface IItem {
  batch_category_id: number;
  modality_id: number;
  modality: string;
  category: string;
  value: number;
  quantity: number;
  ticket?: ITicket;
}

export interface ICart {
  items: IItem[];
  subtotal: number;
}

interface CartContextData {
  cart: ICart;
  handleChangeItems(items: IItem[]): void;
}

export const CartContext = createContext<CartContextData>(
  {} as CartContextData
);

export const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const data = localStorage.getItem('@Koro:cart');

    if (data) {
      return JSON.parse(data) as ICart;
    }

    return {} as ICart;
  });

  const handleChangeItems = useCallback((items: IItem[]) => {
    const subtotal = items.reduce((previous, current) => {
      return previous + current.value;
    }, 0);

    const data = {
      items,
      subtotal,
    };

    localStorage.setItem('@Koro:cart', JSON.stringify(data));

    setCart(data);
  }, []);

  return (
    <CartContext.Provider value={{ cart, handleChangeItems }}>
      {children}
    </CartContext.Provider>
  );
};

export function useCart(): CartContextData {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within an CartProvider');
  }

  return context;
}
