/* eslint-disable no-param-reassign */
import axios from 'axios';
import { differenceInMilliseconds } from 'date-fns';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
api.interceptors.request.use(
  (config) => {
    config.metadata = { startTime: new Date() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    if (response.config.metadata) {
      response.config.metadata.endTime = new Date();
      response.duration = differenceInMilliseconds(
        response.config.metadata.endTime,
        response.config.metadata.startTime
      );
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
