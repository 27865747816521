/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { useGoogleLogin } from '@react-oauth/google';

import getValidationErros from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';

import { Container } from './styles';
import Input from '~/components/Input';
import { KrGoogle, KrInfo, KrSignin } from '../KoroIcons';
import Signup from '../Signup';

interface ILogin {
  signin: boolean;
  onClick(): void;
}

interface IFormData {
  email: string;
  password: string;
}

interface IUserData {
  name: string;
  email: string;
}

const Signin: React.FC<ILogin> = ({ signin, onClick }) => {
  const { signIn, signInGoogle } = useAuth();
  const [signup, setSignup] = useState(false);
  const [userData, setUserData] = useState({} as IUserData);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [signIn]
  );

  const handleSuccess = useCallback(
    async (data) => {
      try {
        await signInGoogle(data, true);
      } catch (error: any) {
        if (error.message === 'User not found') {
          setUserData(error.data);
          setSignup(true);
        } else {
          Swal.fire('Opss...', 'Ocorreu um erro tente novamente', 'error');
        }
      }
    },
    [signInGoogle]
  );

  const handleFailure = useCallback(() => {
    Swal.fire(
      'Oops...',
      'Ocorreu um erro ao realizar o login com google tente novamente ou contate o administrador!'
    );
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleFailure,
  });

  return (
    <>
      {signin && (
        <>
          {signup ? (
            <Signup userData={userData} />
          ) : (
            <Container>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-11 text-center pt-3">
                    <button
                      type="button"
                      onClick={onClick}
                      className="btn-back d-flex align-items-center bg-transparent border-0 mt-3"
                    >
                      <IoIosArrowBack
                        color="#878787"
                        size={12}
                        className="me-2"
                      />{' '}
                      Voltar
                    </button>
                    <div className="banner my-3" />
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h2 className="h5 mb-0 d-flex align-items-center">
                        <KrSignin
                          color="#FF6900"
                          size={20}
                          className="me-2 signin-icon"
                        />
                        Faça Login
                      </h2>
                      <button type="button" className="bg-transparent border-0">
                        <KrInfo color="#8C8C8C" size={16} />
                      </button>
                    </div>
                    <p className="mb-3">
                      Para finalizar a compra, faça login ou cadastre-se. É
                      rápido e fácil!
                    </p>
                  </div>

                  <div className="col-11 px-3">
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className="d-flex flex-column pb-3"
                    >
                      <label className="mb-3">
                        <span className="fw-normal d-block mb-2 size">
                          E-mail
                        </span>
                        <Input type="email" name="email" className="bd-input" />
                      </label>
                      <label className="mb-3">
                        <span className="fw-normal d-block mb-2 size">
                          Senha
                        </span>
                        <Input
                          type="password"
                          name="password"
                          className="bd-input"
                        />
                      </label>

                      <button
                        type="submit"
                        className="btn btn-login h6 fw-normal py-2 mb-0"
                      >
                        Entrar
                      </button>
                      <button
                        type="button"
                        className="d-flex align-items-center justify-content-center my-3 btn btn-login h6 fw-normal py-2"
                        onClick={() => googleLogin()}
                      >
                        <KrGoogle
                          color="#fff "
                          size={20}
                          className="g-icon me-2"
                        />{' '}
                        Entrar com google
                      </button>
                      <p className="text-center account mb-1">
                        Não tem uma conta? Clique{' '}
                        <button type="button" onClick={() => setSignup(true)}>
                          aqui
                        </button>{' '}
                        para cadastrar.
                      </p>
                      <p className="text-center account">
                        Esqueceu sua senha?{' '}
                        <Link to={`${process.env.PUBLIC_URL}/recuperar-senha`}>
                          Recuperar
                        </Link>
                        .
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default Signin;
