import React from 'react';
import { IProps } from './interfaces';

const KrUserDocument: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1979)">
        <path
          d="M18.68 17.52C19.21 16.86 19.53 16.02 19.53 15.11C19.53 12.97 17.8 11.24 15.66 11.24C13.52 11.24 11.79 12.97 11.79 15.11C11.79 16.02 12.11 16.86 12.64 17.52C10.34 18.56 8.73 20.68 8.39 23.2C8.33 23.61 8.46 24.02 8.72 24.33C8.98 24.63 9.36 24.8 9.76 24.8H21.56C21.96 24.8 22.34 24.63 22.6 24.33C22.87 24.02 22.99 23.61 22.94 23.2C22.6 20.68 20.98 18.56 18.68 17.52ZM15.65 12.44C17.12 12.44 18.32 13.64 18.32 15.11C18.32 15.96 17.91 16.77 17.22 17.27C16.76 17.61 16.22 17.78 15.65 17.78C15.08 17.78 14.54 17.6 14.08 17.27C13.39 16.77 12.98 15.96 12.98 15.11C12.98 13.64 14.18 12.44 15.65 12.44ZM21.69 23.54C21.69 23.54 21.61 23.6 21.55 23.6H9.75C9.68 23.6 9.64 23.57 9.61 23.54C9.59 23.51 9.55 23.45 9.56 23.36C9.87 21.09 11.41 19.19 13.57 18.41C13.58 18.41 13.59 18.39 13.61 18.39C14.2 18.76 14.9 18.98 15.65 18.98C16.4 18.98 17.1 18.76 17.69 18.39C17.7 18.39 17.71 18.41 17.73 18.41C19.89 19.19 21.43 21.08 21.74 23.36C21.75 23.45 21.71 23.51 21.69 23.54Z"
          fill={color}
        />
        <path
          d="M26.9 9.6L18.55 0.790002C18.04 0.250002 17.32 -0.0599976 16.57 -0.0599976H6.38C4.88 -0.0599976 3.65 1.16 3.65 2.66V29.16C3.65 30.66 4.87 31.88 6.37 31.88H24.92C26.42 31.88 27.64 30.66 27.64 29.16V11.47C27.64 10.77 27.37 10.11 26.89 9.6H26.9ZM25.85 29.16C25.85 29.67 25.44 30.08 24.93 30.08H6.38C5.87 30.08 5.46 29.67 5.46 29.16V2.66C5.46 2.15 5.87 1.74 6.38 1.74H16.58C16.83 1.74 17.08 1.84 17.25 2.03L25.6 10.84C25.76 11.01 25.85 11.24 25.85 11.47V29.16Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1979">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrUserDocument;
