import styled from 'styled-components';
import retanglesBottom from '~/assets/defaults/retangles-login.svg';

export const Container = styled.div`
  min-height: 100vh;
  background-image: url(${retanglesBottom});
  background-color: #ff6900;
  position: relative;
  background-repeat: no-repeat;
  background-position-y: 680px;
  background-position-x: right;
  overflow-x: hidden;

  h2 {
    font-family: 'Roboto';
  }

  .size {
    font-size: 12px;
  }

  .logo {
    width: 260px;
    height: 60px;
    margin: 75px 0;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #fff;
    color: #ff6900;
  }

  .ret-up {
    position: absolute;
    border-radius: 23px;
    border: 4px solid #fff;
    width: 291px;
    height: 146px;
  }

  .ret-left {
    left: -240px;
    top: -41px;
  }

  .ret-right {
    right: -240px;
    top: 70px;
  }

  svg:not(.g-icon) path {
    fill: #fff;
  }

  @media screen and (min-height: 820px) {
    background-position: bottom right;
  }
`;
