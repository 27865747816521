import React from 'react';
import { IProps } from './interfaces';

const KrSearch: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1865)">
        <path
          d="M10.94 3.34C10.61 3.34 10.34 3.61 10.34 3.94C10.34 4.27 10.61 4.54 10.94 4.54C14.47 4.54 17.34 7.41 17.34 10.94C17.34 11.27 17.61 11.54 17.94 11.54C18.27 11.54 18.54 11.27 18.54 10.94C18.54 6.75 15.13 3.34 10.94 3.34Z"
          fill={color}
        />
        <path
          d="M31.7 30.06L19.49 17.85C19.49 17.85 19.46 17.83 19.44 17.82C20.96 15.94 21.88 13.55 21.88 10.94C21.88 4.9 16.98 0 10.94 0C4.9 0 0 4.9 0 10.94C0 16.98 4.9 21.88 10.94 21.88C13.73 21.88 16.27 20.83 18.2 19.1C18.2 19.1 18.2 19.11 18.21 19.12L30.42 31.33C30.6 31.51 30.83 31.59 31.06 31.59C31.29 31.59 31.52 31.5 31.7 31.33C32.05 30.98 32.05 30.41 31.7 30.06ZM10.94 20.08C5.9 20.08 1.8 15.98 1.8 10.94C1.8 5.9 5.9 1.8 10.94 1.8C15.98 1.8 20.08 5.9 20.08 10.94C20.08 15.98 15.98 20.08 10.94 20.08Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1865">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrSearch;
