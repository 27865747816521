import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 0.87);
  input {
    width: 100%;
    border: none;
    color: #5d5d5d;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }
  .react-datepicker-wrapper {
    flex: 1;
  }
  .react-datepicker-wrapper input {
    cursor: default !important;
  }
`;
