import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Container } from './styles';
import InputSearch from '../InputSearch';
import { KrBell } from '../KoroIcons';
import Avatar from '../Avatar';
import { useAuth } from '~/hooks/Auth';

import avatar from '~/assets/defaults/avatar.svg';

let typingTimer: NodeJS.Timeout;

const Header: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);

  const notifications = [
    {
      notification: 'Sem notificações recentes',
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = useCallback(
    (search) => {
      clearTimeout(typingTimer);

      typingTimer = setTimeout(() => {
        history.push(`${process.env.PUBLIC_URL}/buscar-eventos`, {
          search,
        });
      }, 1000);
    },
    [history]
  );

  return (
    <>
      <Container className="py-3">
        <div id="menu" className="container">
          <div className="row">
            <div className="col-12 px-3 d-flex align-items-center">
              <InputSearch className="flex-fill" onSearch={handleSearch} />
              {!user ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/login`}
                  className="btn-log h5 border-0 bg-transparent mb-0 ms-3"
                >
                  Entrar
                </Link>
              ) : (
                <div className="d-flex ms-3">
                  <div className="position-relative">
                    <button
                      type="button"
                      onClick={() => setShowNotification(!showNotification)}
                      className="border-0 bg-transparent me-3"
                    >
                      <KrBell size={16} color="#9E9E9E" />
                    </button>
                    {showNotification && (
                      <div ref={notificationRef} className="notification">
                        <div className="triangle" />
                        {notifications.map((notification, index) => (
                          <p
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={`py-2 ${
                              index !== notifications.length - 1 &&
                              'border-bottom'
                            }`}
                          >
                            {notification.notification}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                  <Link to={`${process.env.PUBLIC_URL}/perfil/meus-dados`}>
                    <Avatar
                      src={
                        user.additionalInformation.avatar?.archive_url || avatar
                      }
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
