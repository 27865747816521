import React, { useState } from 'react';

import { IoIosArrowDown } from 'react-icons/io';

import { Container, Events, Koro } from './styles';

const Support: React.FC = () => {
  const [activeKoro, setActiveKoro] = useState(false);
  const [activeEvents, setActiveEvents] = useState(false);

  const events = [
    {
      eventNumber: '12347',
    },
    {
      eventNumber: '12347',
    },
    {
      eventNumber: '12347',
    },
  ];

  return (
    <Container className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg px-3 pt-3 mt-3">
            <button
              type="button"
              onClick={() => setActiveKoro(!activeKoro)}
              className="btn-title d-flex justify-content-between align-items-center w-100 mb-3"
            >
              Koro
              <IoIosArrowDown
                size={20}
                color="#7E7E7E"
                className={`${activeKoro ? 'rotate-arrow' : 'right-arrow'}`}
              />
            </button>
            <Koro className="" active={activeKoro}>
              <p className="mb-4">
                Teve problemas com nossa plataforma?{' '}
                <a href="mailto:suporte@koro.com.br">Clique aqui</a> para abrir
                um ticket e entraremos em contato via e-mail em breve.
              </p>
            </Koro>
            {/* <button
              type="button"
              onClick={() => setActiveEvents(!activeEvents)}
              className="btn-title d-flex justify-content-between align-items-center w-100 mt-3"
            >
              Eventos
              <IoIosArrowDown
                size={20}
                color="#7E7E7E"
                className={`${activeEvents ? 'rotate-arrow' : 'right-arrow'}`}
              />
            </button>
            <Events active={activeEvents} cardsQtd={events.length}>
              <p className="mt-3">
                Em caso de problemas com um evento, o organizador é quem irá
                resolver o problema. Passaremos os dados de contato para você e
                também alertaremos o organizador para que seja resolvido
                rapidamente!
              </p>
              <p>
                Vimos alguns dos seus últimos eventos, em qual deles ocorreu o
                problema?
              </p>
              {events.map((event, index) => (
                <a
                  href={`mailto:suporte@koro.com.br?subject=Tive um problema no evento #${event.eventNumber}`}
                  className={`${
                    index !== events.length - 1 && 'border-bottom'
                  } d-inline-block text-start w-100 border-0 p-2 mt-3`}
                >
                  <span className="d-block">Evento</span>

                  <span className="d-block">Evento #{event.eventNumber}</span>
                </a>
              ))}
            </Events> */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Support;
