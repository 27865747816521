import styled from 'styled-components';
import retangles from '~/assets/defaults/retangles-profile.svg';

interface IBar {
  width: number;
}

export const Container = styled.div`
  background: #fff;
  min-height: 100vh;
  .bg-all {
    background: #f4f0ec;
    padding-bottom: 30%;
    min-height: 100vh;
  }

  .btn-children-back {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }
`;

export const TopRide = styled.div`
  background-color: #ff7a00;
  background-image: url(${retangles});
  background-repeat: no-repeat;
  background-position: top right;
  padding: 50px 0;

  h2 {
    font-family: 'Roboto';
  }
  p {
    color: #fff;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }

  .avatar {
    position: relative;
    cursor: pointer;

    > div:not(.btn-photo) {
      width: 96px;
      height: 96px;
      border: 4px solid #002f6c;
    }

    .btn-photo {
      position: absolute;
      right: -5px;
      bottom: -5px;
      width: 40px;
      height: 40px;
      background-color: #002f6c;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .medal {
    width: 90px;
    height: 90px;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
    width: 200px;
  }

  .btn-signout {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
  }

  .notification {
    position: absolute;
    top: 100px;
    right: -11px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    padding: 16px;
    border-radius: 10px;
    width: 90vw;
    z-index: 1;
    p {
      color: #757575;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
    /* .imgs img:nth-child(1) {
      width: 129px;
      height: 92px;
    } */
    .imgs img:nth-child(1) {
      width: 100%;
      max-width: 280px;
      height: 109px;
    }
  }

  .triangle {
    position: absolute;
    top: -8px;
    right: 47px;
    width: 15px;
    height: 15px;
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    background-color: #fff;
    transform: rotate(-45deg);
    border-top-right-radius: 3px;
  }
  @media screen and (min-width: 400px) {
    .notification {
      width: 360px;
    }
  }
`;

export const Bar = styled.div`
  background-color: #aa5111;
  width: 70px;
  height: 4px;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 8px;
`;

export const Progress = styled.div<IBar>`
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
  width: ${(props) => props.width}%;
`;

export const Menu = styled.div`
  a {
    color: #8c8c8c;

    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    border: none;
    border-bottom: 2px solid transparent;
    :hover {
      color: #002f6c;
      font-weight: 600;
      border-bottom: 2px solid #002f6c;
    }
  }

  .active {
    color: #002f6c;
    font-weight: 600;
    border-bottom: 2px solid #002f6c;
  }

  .bg {
    border-radius: 8px;
    background: #fff;
    padding: 23.5px 0;
    margin-top: -35px;
  }
`;
