import React from 'react';
import { IProps } from './interfaces';

const KrCake: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_1909)">
        <path
          d="M30.27 29.6C30.56 29.6 30.8 29.84 30.8 30.13V30.28C30.8 30.57 30.56 30.81 30.27 30.81H1.73C1.44 30.81 1.2 30.57 1.2 30.28V30.13C1.2 29.84 1.44 29.6 1.73 29.6H30.28M30.27 28.4H1.73C0.78 28.4 0 29.17 0 30.13V30.28C0 31.23 0.77 32.01 1.73 32.01H30.28C31.23 32.01 32.01 31.24 32.01 30.28V30.13C32.01 29.18 31.24 28.4 30.28 28.4H30.27Z"
          fill={color}
        />
        <path
          d="M27.47 11.83H25.45V8.4C25.45 7.08 24.69 6.05 23.72 6.05C22.75 6.05 21.99 7.08 21.99 8.4V11.83H17.64V8.4C17.64 7.08 16.88 6.05 15.91 6.05C14.94 6.05 14.18 7.08 14.18 8.4V11.83H9.83001V8.4C9.83001 7.08 9.07001 6.05 8.10001 6.05C7.13001 6.05 6.37001 7.08 6.37001 8.4V11.83H4.35001C2.82001 11.83 1.57001 13.15 1.57001 14.76V28.63C1.57001 29.13 1.97001 29.53 2.47001 29.53C2.97001 29.53 3.37001 29.13 3.37001 28.63V18.28C4.71001 20.5 5.90001 21.42 6.98001 21.06C8.00001 20.73 8.85001 19.59 9.67001 18.49C10.3 17.65 11.15 16.51 11.63 16.58C12.1 16.67 12.7 17.8 13.14 18.62C13.8 19.86 14.49 21.15 15.55 21.15C15.65 21.15 15.75 21.14 15.86 21.11C16.87 20.89 17.49 19.67 18.1 18.5C18.49 17.74 19.08 16.59 19.48 16.59C19.81 16.6 20.09 17.3 20.37 17.97C20.82 19.07 21.37 20.43 22.81 20.65C24.3 20.88 25.2 19.42 25.99 18.13C26.5 17.29 27.09 16.34 27.6 16.31C27.96 16.29 28.24 16.28 28.45 16.28V28.62C28.45 29.12 28.85 29.52 29.35 29.52C29.85 29.52 30.25 29.12 30.25 28.62V14.75C30.25 13.13 29 11.82 27.47 11.82V11.83ZM23.19 8.4C23.19 7.68 23.53 7.25 23.72 7.25C23.91 7.25 24.25 7.69 24.25 8.4V11.83H23.19V8.4ZM15.37 8.4C15.37 7.68 15.71 7.25 15.9 7.25C16.09 7.25 16.43 7.69 16.43 8.4V11.83H15.37V8.4ZM7.56001 8.4C7.56001 7.68 7.90001 7.25 8.09001 7.25C8.28001 7.25 8.62001 7.69 8.62001 8.4V11.83H7.56001V8.4ZM27.53 15.13C26.38 15.19 25.66 16.38 24.96 17.52C24.34 18.54 23.69 19.59 22.99 19.48C22.28 19.37 21.93 18.64 21.48 17.53C21.08 16.55 20.63 15.45 19.53 15.4C18.36 15.33 17.67 16.67 17.02 17.96C16.61 18.76 16.05 19.85 15.59 19.95C15.26 20.05 14.56 18.75 14.19 18.07C13.55 16.87 12.88 15.62 11.86 15.42C10.65 15.18 9.70001 16.44 8.70001 17.79C8.03001 18.69 7.26001 19.72 6.60001 19.94C6.24001 20.06 5.11001 19.31 3.37001 15.78V14.78C3.37001 14.16 3.81001 13.65 4.35001 13.65H27.48C28.02 13.65 28.46 14.16 28.46 14.78V15.11C28.21 15.11 27.91 15.12 27.54 15.14L27.53 15.13Z"
          fill={color}
        />
        <path
          d="M8.09001 4.67C8.81001 4.67 9.40001 3.95 9.40001 3.06C9.40001 2.17 8.81001 0.130001 8.09001 0.130001C7.37001 0.130001 6.78001 2.17 6.78001 3.06C6.78001 3.95 7.37001 4.67 8.09001 4.67Z"
          fill={color}
        />
        <path
          d="M15.9 4.67C16.62 4.67 17.21 3.95 17.21 3.06C17.21 2.17 16.62 0.130001 15.9 0.130001C15.18 0.130001 14.59 2.17 14.59 3.06C14.59 3.95 15.18 4.67 15.9 4.67Z"
          fill={color}
        />
        <path
          d="M23.72 4.67C24.44 4.67 25.03 3.95 25.03 3.06C25.03 2.17 24.44 0.130001 23.72 0.130001C23 0.130001 22.41 2.17 22.41 3.06C22.41 3.95 23 4.67 23.72 4.67Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_1909">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrCake;
